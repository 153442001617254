import React from 'react';
import styled, { keyframes } from 'styled-components';

import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';
import SideController from './SideController';

const Header: React.FC = () => {
  return (
    <Wrapper>
      <SideLogo id="handled-visible-header">
        <IconsWrapper>
          <KIcon src={require('../../assets/images/icons/logo-k-2x.png')} alt="K - icon" />
          <KGIcon src={require('../../assets/images/icons/logo-kg-2x.png')} alt="Kit Global - icon" />
        </IconsWrapper>
      </SideLogo>
      <SideController />
    </Wrapper>
    );
  }
  
export default Header;

const Animation = keyframes`
  0% {
    right: -100%;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
`;

const KIcon = styled.img`
 height: 54px;
`;

const KGIcon = styled.img`
  height: 23px;
  margin-left: 25px;
  padding-bottom: 8px;
  @media ${device.tablet} {
    display: none;
  }
`;
  
const Wrapper = styled.div`
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  position: relative;
  animation: ${Animation} 0.7s ease-in-out 0s 1;
`;

const SideLogo = styled.div`
  position: absolute;
  left: 20%;
  height: 100px;
  ${flexCSS('row', 'flex-end', 'center')}
  @media ${device.laptopM} {
    left: 10%;
  }
  @media ${device.tablet} {
    left: 5%;
  }
`;

const IconsWrapper = styled.div`
  padding-bottom: 18px;
`;