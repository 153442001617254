import React, { createContext, useState, useLayoutEffect } from 'react';

const LanguageContext = createContext({
  language: 'ru',
  toggle: (language: 'ru' | 'en' | 'ua') => { },
});

export default LanguageContext;

export const LanguageProvider = (props: any) => {
  const [language, setLanguage]: any = useState(window.localStorage.getItem('language'));

  useLayoutEffect(() => {
    const lastLang = window.localStorage.getItem('language');
    if (lastLang === 'ru') setLanguage('ru');
    if (lastLang === 'ua') setLanguage('ua');
    if (lastLang === 'en') setLanguage('en');
    if (!lastLang) setLanguage('ua');
  }, [language]);

  const toggle = (lang: 'ru' | 'en' | 'ua') => {
    setLanguage(lang);
    window.localStorage.setItem('language', lang);
  };

  return <LanguageContext.Provider value={{ language, toggle }}>
    {props.children}
  </LanguageContext.Provider>
}

export const TranslatorObject: {
  [name: string]: {
    [name: string]: any
  }
} = {
  Greeting: {
    themeDark: {
      ru: 'Темная',
      ua: 'Темна',
      en: 'Dark'
    },
    themeLight: {
      ru: 'Светлая',
      ua: 'Світла',
      en: 'Light'
    }
  },
  OtherThings: {
    title: {
      ru: 'Русское Агентство',
      ua: 'Українське агентство',
      en: 'American Agency'
    }
  },
  _Greeting: {
    _1Part: {
      ru: 'Aгентство',
      ua: 'Aгентство',
      en: 'Agency of'
    },
    _2Part: {
      ru: 'и',
      ua: 'і',
      en: 'and'
    },
    _3Part: {
      ru: 'решений,',
      ua: 'рішень,',
      en: 'solutions,'
    },
    _3_2Part: {
      ru: 'дизайн и разработка',
      ua: 'дизайн і розробка',
      en: 'design and development'
    },
    _4Part: {
      ru: 'Комплексные информационные технологии для бизнеса',
      ua: 'Комплексні інформаційні технології на користь бізнесу',
      en: 'Integrated information technology for business'
    }
  },
  Services: {
    title: {
      ru: 'Услуги',
      ua: 'Послуги',
      en: 'Services'
    }
  },
  Portfolio: {
    title: {
      ru: 'Портфолио',
      ua: 'Портфоліо',
      en: 'Portfolio'
    },
    link: {
      ru: 'Другие работы на сайте https://kitapp.pro/',
      ua: 'Інші роботи на сайті https://kitapp.pro/',
      en: 'Other projects on the site https://kitapp.pro/'
    },
    linkWeb: {
      ru: 'Другие работы на сайте https://kitweb.pro/',
      ua: 'Інші роботи на сайті https://kitweb.pro/',
      en: 'Other projects on the site https://kitwebagency.com/'
    }
  },
  Steps: {
    title_steps: {
      ru: 'Этапы работы',
      ua: 'Етапи роботи',
      en: 'Stages of work'
    },
    title_advantages: {
      ru: 'КИТ Глобал: выгодные условия сотрудничества',
      ua: 'КІТ Глобал: вигідні умови співробітництва',
      en: 'KIT Global: favorable conditions for cooperation'
    }
  },
  WebService: {
    _1Part: {
      ru: 'A',
      ua: 'B',
      en: 'C'
    },
    _2Part: {
      ru: 'D',
      ua: 'E',
      en: 'F'
    },
    _3Part: {
      ru: 'D',
      ua: 'E',
      en: 'F'
    },
    _4Part: {
      ru: 'D',
      ua: 'E',
      en: 'F'
    }
  },
  MobileService: {
    _1Part: {
      ru: 'A',
      ua: 'B',
      en: 'C'
    },
    _2Part: {
      ru: 'D',
      ua: 'E',
      en: 'F'
    },
    _3Part: {
      ru: 'D',
      ua: 'E',
      en: 'F'
    },
    _4Part: {
      ru: 'D',
      ua: 'E',
      en: 'F'
    }
  },
  Form: {
    title: {
      ru: 'Получите больше информации о возможностях сотрудничества',
      en: 'Get more information on collaboration opportunities',
      ua: 'Отримайте більше інформації про можливості співпраці'
    },
    placeholderName: {
      ru: 'Имя',
      en: 'Name',
      ua: "Ім'я"
    },
    placeholderEmail: {
      ru: 'Телефон или почта',
      en: 'Phone or mail',
      ua: 'Телефон або пошта'
    },
    buttonText: {
      ru: 'Отправить контактные данные',
      en: 'Send contact details',
      ua: 'Відправити контактні дані'
    }
  }
}