import React from 'react';
import styled from 'styled-components';

import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';

const Advantage: React.FC<{
  index: string, title: string, img: string, style?: any
}> = ({index, title, style, children, img}) => {
  return (
    <Wrapper>
      <Image src={require('../../assets/images/advantages/'+img+'.png')}/>
      <Description>{title}</Description>
    </Wrapper>
    );
  }
  
export default Advantage;

const Title = styled.div`
  font-weight: 700;
  font-size: 40pt;
  color: var(--theme-step-color);
  margin-right: 10px;
  @media ${device.mobile} {
    font-size: 30pt;
    margin-right: 0px;
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  transition: 0.3s;
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  padding-bottom: 50px;
  ${flexCSS('column')}
  &:hover {
    box-shadow: 0 0 8px var(--c-orange);
  }
  &:hover ${Title} {
    color: var(--c-orange);
  }
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 250px;
  height: 180px;
  @media ${device.mobileC} {
    width: 45%;
    padding: 5px 0;
    heigth: fit-content;
    box-shadow: 0 0 12px var(--c-orange);
  }
  @media ${device.mobile} {
    width: 43%;
  }
  @media ${device.mobileXM} {
    height: 150px;
  } 
  @media ${device.mobileS} {
    height: 140px;
  } 
`;

const Image = styled.img`
  height: 65px;
  margin-bottom: 40px;
  @media ${device.mobile} {
    margin-bottom: 20px;
  } 
  @media ${device.mobileXM} {
    height: 50px;
  } 
  @media ${device.mobileS} {
    height: 45px;
  }
`;

const Description = styled.div`
  text-align: center;
  font-size: 11pt;
  font-weight: 700;
  padding: 0 10px;
  @media ${device.mobileXM} {
    font-size: 10pt;
  }
  @media ${device.mobileS} {
    font-size: 9pt;
  }
`;