import React, { useState, useContext } from 'react';
import './App.css';
import { configureAnchors } from 'react-scrollable-anchor'

import { GlobalFontsStyle } from './assets/fonts/fonts';
import SideLinks from './components/SideLinks';
import { SideLeftIcon } from './components/Icon';
import GreetingWrapper from './components/Greeting/GreetingWrapper';
import ServicesWrapper from './components/Services/ServicesWrapper';
import PortfolioWrapper from './components/Portfolio/PortfolioWrapper';
import StepsWrapper from './components/StepsWorking/StepsWrapper';
import CrewWrapper from './components/Crew/CrewWrapper';
import AboutWrapper from './components/AboutUS/AboutWrapper';
import BespeakWrapper from './components/Bespeak/BespeakWrapper';
import FooterWrapper from './components/Footer/FooterWrapper';
import ModalCall from './components/Greeting/ModalCall';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LanguageContext from './contexts/Language';
import ProductsWrapper from './components/Products/ProductsWrapper';

const App: React.FC = () => {
  const [orderModal, setOrderModal] = useState(false);
  const { language } = useContext(LanguageContext);
  configureAnchors({ offset: -50, scrollDuration: 200 });
  const handleClick = () => setOrderModal(true);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcnLc4UAAAAAGbX0Mjro9Yv5NKN4YQsPTm_Z6lc"
      language={language === 'ua' ? 'uk' : language}
    >
      <div className="App">
        <GlobalFontsStyle />
        <GreetingWrapper />
        <ServicesWrapper />
        <PortfolioWrapper />
        <ProductsWrapper />
        <StepsWrapper />
        <CrewWrapper />
        <AboutWrapper />
        <BespeakWrapper />
        <FooterWrapper />
        <SideLinks />
        {orderModal ? <ModalCall switch={() => setOrderModal(false)} /> : null}
        <SideLeftIcon onClick={() => handleClick()} src={require('./assets/images/icons/phone.png')} alt="Call icon" />
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
