interface IDevice {
  [sizeScreen: string]: string
}

enum ESize {
  mobileS = '320px',
  mobileXM = '350px',
  mobileM = '375px',
  mobile = '425px',
  mobileL = '485px',
  mobileC = '600px',
  tablet = '768px',
  laptopM = '900px',
  laptop = '1024px',
  laptopX = '1100px',
  laptopL = '1440px',
  desktop = '2560px'
}

export const device: IDevice = {
  mobileS: `(max-width: ${ESize.mobileS})`,
  mobileM: `(max-width: ${ESize.mobileM})`,
  mobileXM: `(max-width: ${ESize.mobileM})`,
  mobile: `(max-width: ${ESize.mobile})`,
  mobileL: `(max-width: ${ESize.mobileL})`,
  mobileC: `(max-width: ${ESize.mobileC})`,
  tablet: `(max-width: ${ESize.tablet})`,
  laptopM: `(max-width: ${ESize.laptopM})`,
  laptop: `(max-width: ${ESize.laptop})`,
  laptopX: `(max-width: ${ESize.laptopX})`,
  laptopL: `(max-width: ${ESize.laptopL})`,
  desktop: `(max-width: ${ESize.desktop})`,
  desktopL: `(max-width: ${ESize.desktop})`
};