import React from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';
import EcommWrapper from './EcommWrapper';
import RestupWrapper from './RestupWrapper';

const ProductsWrapper: React.FC = ({ children }) => {
  return (
    <ScrollableAnchor id={'products'}>
      <Wrapper>
        <RestupWrapper />
        <EcommWrapper />
      </Wrapper>
    </ScrollableAnchor>
  )
}

export default ProductsWrapper;

const Wrapper = styled.div`
  
`