interface IButton {
  text: {
    [language: string]: string
  }
}

export interface ISlider {
  id: string,
  src: string,
  index: number,
  link?: string
}

export interface ISliders {
  [application: string]: ISlider[]
}

interface IJSON_sliders {
  mobile: ISlides[],
  web: ISlides[]
}

interface ILinkText {
  mobile: {
    [language: string]: string
  },
  web?: {
    [language: string]: string
  }
}

export interface ISlides {
  id: number,
  title: {
    [name: string]: string
  },
  slug: {
    [name: string]: string
  },
}

export const petshealth: ISlider[] = [
  {
    id: 'petshealth-1',
    src: 'object-1/petshealth-1.png',
    index: 1,
    link: 'https://kitapp.pro/en/portfolio/petshealth-app/'
  },
  {
    id: 'petshealth-2',
    src: 'object-1/petshealth-2.png',
    index: 2
  },
  {
    id: 'petshealth-3',
    src: 'object-1/petshealth-3.png',
    index: 3
  },
  {
    id: 'petshealth-4',
    src: 'object-1/petshealth-4.png',
    index: 4
  },
  {
    id: 'petshealth-5',
    src: 'object-1/petshealth-5.png',
    index: 5
  }
];

export const apexpaint: ISlider[] = [
  {
    id: 'apexpaint-1',
    src: 'object-2/ap-1.png',
    index: 1,
    link: 'https://kitapp.pro/en/portfolio/apexpaint/'
  },
  {
    id: 'apexpaint-2',
    src: 'object-2/ap-2.png',
    index: 2
  },
  {
    id: 'apexpaint-3',
    src: 'object-2/ap-3.png',
    index: 3
  },
  {
    id: 'apexpaint-4',
    src: 'object-2/ap-4.png',
    index: 4
  },
  {
    id: 'apexpaint-5',
    src: 'object-2/ap-4.png',
    index: 5
  }
];

export const vestum: ISlider[] = [
  {
    id: 'vestum-1',
    src: 'object-3/vestum1.png',
    index: 1,
    link: 'https://kitapp.pro/en/portfolio/vestum/'
  },
  {
    id: 'vestum-2',
    src: 'object-3/vestum2.png',
    index: 2
  },
  {
    id: 'vestum-3',
    src: 'object-3/vestum3.png',
    index: 3
  },
  {
    id: 'vestum-4',
    src: 'object-3/vestum4.png',
    index: 4
  },
  {
    id: 'vestum-5',
    src: 'object-3/vestum5.png',
    index: 5
  }
];

export const kuchenchef: ISlider[] = [
  {
    id: 'kuchenchef-1',
    src: 'object-4/kuh-1.png',
    index: 1,
    link: 'https://kitapp.pro/en/portfolio/kuchenchef/'
  },
  {
    id: 'kuchenchef-2',
    src: 'object-4/kuh-2.png',
    index: 2
  },
  {
    id: 'kuchenchef-3',
    src: 'object-4/kuh-3.png',
    index: 3
  },
  {
    id: 'kuchenchef-4',
    src: 'object-4/kuh-4.png',
    index: 4
  },
  {
    id: 'kuchenchef-5',
    src: 'object-4/kuh-5.png',
    index: 5
  }
];

export const perecuppro: ISlider[] = [
  {
    id: 'perecuppro-1',
    src: 'object-5/perecup1.png',
    index: 1,
    link: 'https://kitapp.pro/en/portfolio/perecuppro/'
  },
  {
    id: 'perecuppro-2',
    src: 'object-5/perecup2.png',
    index: 2
  },
  {
    id: 'perecuppro-3',
    src: 'object-5/perecup3.png',
    index: 3
  },
  {
    id: 'perecuppro-4',
    src: 'object-5/perecup4.png',
    index: 4
  },
  {
    id: 'perecuppro-5',
    src: 'object-5/perecup5.png',
    index: 5
  }
];

const site1: ISlider[] = [
  {
    id: 'site1-1',
    src: 'object-1/slide-1.jpg',
    index: 1,
    link: 'https://odessasturgeon.com/'
  },
  {
    id: 'site1-2',
    src: 'object-1/slide-2.jpg',
    index: 2,
    link: 'https://odessasturgeon.com/'
  },
  {
    id: 'site1-3',
    src: 'object-1/slide-3.jpg',
    index: 3,
    link: 'https://odessasturgeon.com/'
  },
  {
    id: 'site1-4',
    src: 'object-1/slide-4.jpg',
    index: 4,
    link: 'https://odessasturgeon.com/'
  },
  {
    id: 'site1-5',
    src: 'object-1/slide-5.jpg',
    index: 5,
    link: 'https://odessasturgeon.com/'
  }
];

const site2: ISlider[] = [
  {
    id: 'site2-1',
    src: 'object-2/slide-1.jpg',
    index: 1,
    link: 'https://cristallin.ru/'
  },
  {
    id: 'site2-2',
    src: 'object-2/slide-2.jpg',
    index: 2,
    link: 'https://cristallin.ru/'
  },
  {
    id: 'site2-3',
    src: 'object-2/slide-3.jpg',
    index: 3,
    link: 'https://cristallin.ru/'
  },
  {
    id: 'site2-4',
    src: 'object-2/slide-4.jpg',
    index: 4,
    link: 'https://cristallin.ru/'
  },
  {
    id: 'site2-5',
    src: 'object-2/slide-5.jpg',
    index: 5,
    link: 'https://cristallin.ru/'
  }
];

const site3: ISlider[] = [
  {
    id: 'site3-1',
    src: 'object-3/slide-1.jpg',
    index: 1,
    link: 'https://www.promproekt.sumy.ua/'
  },
  {
    id: 'site3-2',
    src: 'object-3/slide-2.jpg',
    index: 2,
    link: 'https://www.promproekt.sumy.ua/'
  },
  {
    id: 'site3-3',
    src: 'object-3/slide-3.jpg',
    index: 3,
    link: 'https://www.promproekt.sumy.ua/'
  },
  {
    id: 'site3-4',
    src: 'object-3/slide-4.jpg',
    index: 4,
    link: 'https://www.promproekt.sumy.ua/'
  },
  {
    id: 'site3-5',
    src: 'object-3/slide-5.jpg',
    index: 5,
    link: 'https://www.promproekt.sumy.ua/'
  }
];

const site4: ISlider[] = [
  {
    id: 'site4-1',
    src: 'object-4/slide-1.jpg',
    index: 1,
    link: 'https://splav500.com.ua/'
  },
  {
    id: 'site4-2',
    src: 'object-4/slide-2.jpg',
    index: 2,
    link: 'https://splav500.com.ua/'
  },
  {
    id: 'site4-3',
    src: 'object-4/slide-3.jpg',
    index: 3,
    link: 'https://splav500.com.ua/'
  },
  {
    id: 'site4-4',
    src: 'object-4/slide-4.jpg',
    index: 4,
    link: 'https://splav500.com.ua/'
  },
  {
    id: 'site4-5',
    src: 'object-4/slide-5.jpg',
    index: 5,
    link: 'https://splav500.com.ua/'
  }
];

const site5: ISlider[] = [
  {
    id: 'site5-1',
    src: 'object-5/slide-1.jpg',
    index: 1,
    link: 'http://okvd.sumy.ua/'
  },
  {
    id: 'site5-2',
    src: 'object-5/slide-2.jpg',
    index: 2,
    link: 'http://okvd.sumy.ua/'
  },
  {
    id: 'site5-3',
    src: 'object-5/slide-3.jpg',
    index: 3,
    link: 'http://okvd.sumy.ua/'
  },
  {
    id: 'site5-4',
    src: 'object-5/slide-4.jpg',
    index: 4,
    link: 'http://okvd.sumy.ua/'
  },
  {
    id: 'site5-5',
    src: 'object-5/slide-5.jpg',
    index: 5,
    link: 'http://okvd.sumy.ua/'
  }
];

export const MobileSlides: ISliders = {
  petshealth,
  apexpaint,
  vestum,
  kuchenchef,
  perecuppro
}

export const WebSlides: ISliders = {
  site1,
  site2,
  site3,
  site4,
  site5
}

export const ButtonText: IButton = {
  text: {
    ru: 'Заказать разработку',
    ua: 'Замовити розробку',
    en: 'To order development'
  }
}

export const JSON_list: IJSON_sliders = {
  mobile: [
    {
      id: 0,
      title: {
        'ru': 'Мобильное приложение PETSHEALTH',
        'ua': 'Мобільний додаток PETSHEALTH',
        'en': 'Mobile App PETSHEALTH'
      },
      slug: { name: 'petshealth' }
    }, {
      id: 1,
      title: {
        'ru': 'Мобильное приложение APEXPAINT',
        'ua': 'Мобільний додаток APEXPAINT',
        'en': 'Mobile App APEXPAINT'
      },
      slug: { name: 'apexpaint' }
    }, {
      id: 2,
      title: {
        'ru': 'Мобильное приложение VESTUM',
        'ua': 'Мобільний додаток VESTUM',
        'en': 'Mobile App VESTUM'
      },
      slug: { name: 'vestum' }
    }, {
      id: 3,
      title: {
        'ru': 'Мобильное приложение KUCHENCHEF',
        'ua': 'Мобільний додаток KUCHENCHEF',
        'en': 'Mobile App KUCHENCHEF'
      },
      slug: { name: 'kuchenchef' }
    }, {
      id: 4,
      title: {
        'ru': 'Мобильное приложение PERECUPPRO',
        'ua': 'Мобільний додаток PERECUPPRO',
        'en': 'Mobile App PERECUPPRO'
      },
      slug: { name: 'perecuppro' }
    }
  ],
  web: [{
    id: 1,
    title: {
      'ru': 'Разработка сайта по продаже икры и рыбной продукции',
      'ua': 'Розробка сайту з продажу ікри та рибних товарів',
      'en': 'Development a website for the sale of caviar and fish products'
    },
    slug: { name: 'site1' }
  }, {
    id: 2,
    title: {
      'ua': 'Розробка інтернет-магазину офтальмологічних товарів',
      'ru': 'Разработка интернет-магазина офтальмологических товаров',
      'en': 'Development an online store for ophthalmic products'
    },
    slug: { name: 'site2' }
  }, {
    id: 3,
    title: {
      'ru': 'Создание сайта для проектной организации',
      'ua': 'Створення сайту для проектної організації',
      'en': 'Creating a website for a design organization'
    },
    slug: { name: 'site3' }
  },
  {
    id: 4,
    title: {
      'ru': 'Разработка интернет-магазина строительных материалов',
      'ua': 'Розробка інтернет-магазину будівельних матеріалів',
      'en': 'Development an online store of building materials'
    },
    slug: { name: 'site4' }
  },
  {
    id: 5,
    title: {
      'ru': 'Создание сайта для медицинского учреждения',
      'ua': 'Створення сайту для медичної установи',
      'en': 'Creating a website for a medical institution'
    },
    slug: { name: 'site5' }
  }]
}

export const LinkText: ILinkText = {
  mobile: {
    ru: 'Смотреть на AppStore | GooglePlay',
    eng: 'View on the AppStore | GooglePlay',
    ua: 'Дивитися на AppStore | GooglePlay'
  }
}