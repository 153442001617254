import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { goToAnchor } from 'react-scrollable-anchor';

import LanguageContext from '../../contexts/Language';
import AnchorContext from '../../contexts/Anchor';
import { flexCSS } from '../../assets/FitCSS';
import { HeadData } from './JSON';
import { device } from '../../styled-components/MediaQueries';

import SideController from '../Greeting/SideController';

const MinimalisticHeader: React.FC<{
  visible: boolean, defaultV: boolean | 'EMMITED', switchV: Function
}> = ({ visible, defaultV, switchV }) => {
  const { language } = useContext(LanguageContext);
  const { anchore, toggleAnchor } = useContext(AnchorContext);

  const handleAnchor = (anchor: 'greeting' | 'services' | 'portfolio' | 'products' | 'stages' | 'rules' | 'crew' | 'whoarewe' | 'toorder') => {
    goToAnchor(anchor, true);
    toggleAnchor(anchor);
  }

  return (
    <Wrapper visible={visible}>
      <SubWrapper>
        <IconWrapper>
          <KIcon
            src={require('../../assets/images/icons/logo-k-1x.png')}
            onClick={() => handleAnchor('greeting')}
          />
        </IconWrapper>
        <Row>
          <Text active={anchore === 'services'} onClick={() => handleAnchor('services')}>{HeadData.services[language]}</Text>
          <Text active={anchore === 'portfolio'} onClick={() => handleAnchor('portfolio')}>{HeadData.portfolio[language]}</Text>
          <Text active={anchore === 'products'} onClick={() => handleAnchor('products')}>{HeadData.products[language]}</Text>
          <Text active={anchore === 'stages'} onClick={() => handleAnchor('stages')}>{HeadData.steps[language]}</Text>
          <Text active={anchore === 'rules'} onClick={() => handleAnchor('rules')}>{HeadData.rules[language]}</Text>
          <Text active={anchore === 'crew'} onClick={() => handleAnchor('crew')}>{HeadData.crew[language]}</Text>
          <Text active={anchore === 'whoarewe'} onClick={() => handleAnchor('whoarewe')}>{HeadData.whoarewe[language]}</Text>
          <Text active={anchore === 'toorder'} onClick={() => handleAnchor('toorder')}>{HeadData.toorder[language]}</Text>
        </Row>
        <MinimalicticRow>
          <SideController />
        </MinimalicticRow>
      </SubWrapper>
    </Wrapper>
  );
}

export default MinimalisticHeader;

const AnimationSideIn = keyframes`
  0% { top: -100px; }
  100% { top: 0; }
`;

const AnimationSideOut = keyframes`
  0% { top: 0; }
  100% { top: -100px; }
`;

const Wrapper = styled.div<{ visible: true | false }>`
  background: var(--theme-bg2-color);
  padding: 10px 0;
  position: fixed;
  z-index: 100;
  top: ${props => props.visible ? '0' : '-100px'};
  width: 100%;
  box-shadow: 0 0 5px var(--c-orange);
  ${flexCSS('row')}
  animation: ${props => props.visible ? AnimationSideIn : AnimationSideOut} 0.5s ease-in-out 0s 1;
  
`;

const SubWrapper = styled.div`
  width: 60%;
  @media ${device.laptop} {
    width: 85%;
  }
  ${flexCSS('row', 'center', 'space-between')}
  @media ${device.mobileC} {
    width: 95%;
  }
`;

const IconWrapper = styled.div`
  width: 20%;
  text-align: start;
  @media ${device.tablet} {
    width: 10%;
  }
`;

const Row = styled.div`
  width: 80%;
  ${flexCSS('row', 'center', 'space-around')}
  @media ${device.tablet} {
    width: 90%;
    font-size: 10pt;
  }
  @media ${device.mobileL} {
    display: none;
  }
`;

const MinimalicticRow = styled.div`
  width: 80%;
  ${flexCSS('row', 'center', 'space-around')}
  display: none;
  @media ${device.mobileL} {
    display: flex;
  }
`;

const Text = styled.div<{ active: boolean }>`
  cursor: pointer;
  transition: 0.5s;
  font-weight: 600;
  &:hover {
    color: var(--c-orange);
  }
  margin-right: 10px;
  ${props => props.active ? 'color: var(--c-orange)' : null}
`;

const KIcon = styled.img`
  height: 37px;
  @media ${device.mobileL} {
    height: 32px;
  }
  @media ${device.mobile} {
    height: 28px;
  }
`;