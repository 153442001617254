import React from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import { flexCSS } from '../../assets/FitCSS';
import Contacts from  './Contacts';
import { device } from '../../styled-components/MediaQueries';

const FooterWrapper: React.FC = () => {
  return (
    <ScrollableAnchor id={'footer'}>
      <Wrapper>
        <SubWrapper>
          <IconWrapper>
            <Icon src={require('../../assets/images/icons/logo-k-1x.png')}/>
          </IconWrapper>
          <TextWrapper>
            <Contacts />
          </TextWrapper>
        </SubWrapper>
      </Wrapper>
    </ScrollableAnchor>
    );
  }
  
export default FooterWrapper;

const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg2-color);
  color: var(--theme-font-color);
  padding: 40px 0 50px;
  ${flexCSS('row', 'center', 'center')}
  @media ${device.mobileC} {
    padding: 20px 0 20px;
  }
`;

const SubWrapper = styled.div`
  width: 65%;
  ${flexCSS('row', 'center', 'space-between')}
  margin-left: 30px;
  @media ${device.laptop} {
    width: 85%;
    margin-left: 50px;
  }
  @media ${device.mobileC} {
    width: 95%;
  }
  @media ${device.mobileXM} {
    margin-left: 0px;
  }
`;

const IconWrapper = styled.div`
  width: 20%;
  @media ${device.laptop} {
    width: 10%;
  }
  ${flexCSS('row', 'center', 'flex-start')}
  @media ${device.mobileC} {
    display: none;
  }
`;

const TextWrapper = styled.div`
  width: 80%;
  @media ${device.laptop} {
    width: 90%;
  }
  @media ${device.mobileXM} {
    width: 100%;
  }
  ${flexCSS('row', 'center', 'space-between')}
`;

const Icon = styled.img`
  @media ${device.tablet} {
    height: 40px;
  }
`;