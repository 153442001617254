import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import LanguageContext from '../../contexts/Language';
import { flexCSS } from '../../assets/FitCSS';

interface IModal {
  switch: Function
}

const SwitcherLanguage: React.FC<IModal> = (props) => {
  const { language, toggle } = useContext(LanguageContext);
  const [side, sideOut] = useState<'in' | 'out'>('in')

  const handleClick = () => {
    sideOut('out');
    setTimeout(() => props.switch(), 700);
  }

  return (
    <Switcher type={side} onClick={() => handleClick()}>
      <Languages>
        <Language active={language === 'ua'} onClick={() => toggle('ua')}>UA</Language>
        <Language active={language === 'ru'} onClick={() => toggle('ru')}>RU</Language>
        <Language active={language === 'en'} onClick={() => toggle('en')}>EN</Language>
      </Languages>
    </Switcher>
  ); 
}

export default SwitcherLanguage;

const AnimationSwitchSideIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const AnimationSwitchSideOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const Switcher = styled.div<{type: 'in' | 'out'}>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  background-color: #00000090;
  ${props => props.type === 'in' ? 'opacity: 1;' : 'opacity: 0;'}
  animation: ${props => props.type === 'in' ? AnimationSwitchSideIn : AnimationSwitchSideOut} 0.5s ease-in-out 0s 1;
`;

const Languages = styled.div`
  margin-top: 50px;
  width: 100%;
  ${flexCSS('row')}
`;

const Language = styled.div<{active: true | false}>`
  width: 50px;
  padding: 5px;
  background: var(--theme-bg-color);
  border: 1px solid var(--c-orange);
  border-radius: 3px;
  margin: 3px;
  font-weight: 700;
  color: white;
  opacity: 1;
  cursor: pointer;
  ${props => props.active ? 'background-color: var(--c-orange);' : null}
  ${props => props.active ? 'box-shadow: 0px 0px 7px var(--c-orange);' : null}
`;