import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import Header from './Header';
import MinimalisticHeader from  '../Header/Header';
import LanguageContext, { TranslatorObject } from '../../contexts/Language';
import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';

interface ImgProps {
  src?: string;
}

const GreetingWrapper: React.FC = () => {
  let defaultVisible: boolean | 'EMMITED' = false;
  const { language } = useContext(LanguageContext);
  const [visible, setVisible] = useState<boolean>(defaultVisible);

  const SwitchDefault = () => defaultVisible = 'EMMITED';

  const HandleScroll = (target: HTMLElement | null): void => {
    if(!target) return setVisible(false);
    let targetPosition = {
        Ttop: window.pageYOffset + target.getBoundingClientRect().top,
        Tleft: window.pageXOffset + target.getBoundingClientRect().left,
        Tright: window.pageXOffset + target.getBoundingClientRect().right,
        Tbottom: window.pageYOffset + target.getBoundingClientRect().bottom
      },
      windowPosition = {
        Wtop: window.pageYOffset,
        Wleft: window.pageXOffset,
        Wright: window.pageXOffset + document.documentElement.clientWidth,
        Wbottom: window.pageYOffset + document.documentElement.clientHeight
      };
    const { Tbottom, Tleft, Tright, Ttop } = targetPosition;
    const { Wbottom, Wleft, Wright, Wtop } = windowPosition;
    if (Tbottom > Wtop && Ttop < Wbottom && Tright > Wleft && Tleft < Wright) return setVisible(false);
    else return setVisible(true);
  }
  
  useEffect((): void => {
    window.addEventListener('scroll', 
      () => HandleScroll(document.getElementById('handled-visible-header'))
    );
  });

  return (
    <ScrollableAnchor id={'greeting'}>
      <Wrapper>
        <Header/>
        {/* <WrapperForK>
          <WrapperForBG src={require('../../assets/images/icons/global-bg-2x.png')}/>
        </WrapperForK> */}
        <WrapperForText>
          <WrapperForTitle>
            <Title>
              {TranslatorObject._Greeting._1Part[language]} 
              <span style={{'color': 'var(--c-orange)'}}> Web </span> 
              {TranslatorObject._Greeting._2Part[language]} 
              <span style={{'color': 'var(--c-orange)'}}> Mobile </span> 
              {TranslatorObject._Greeting._3Part[language]}<br />
              {TranslatorObject._Greeting._3_2Part[language]}
            </Title>
            <SubTitle>{TranslatorObject._Greeting._4Part[language]}</SubTitle>
          </WrapperForTitle>
        </WrapperForText>
        <MinimalisticHeader visible={visible} defaultV={defaultVisible} switchV={() => SwitchDefault()}/>
      </Wrapper>
    </ScrollableAnchor>
    
    );
  }
  
export default GreetingWrapper;
  
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-position-x: left;
  background-position-y: bottom;
  background-image: url(${require('../../assets/images/icons/global-bg-2x.png')});
`;

const Animation = keyframes`
  0% {
    right: -100%;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
`;

const WrapperForText = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  ${flexCSS('row', 'center', 'center')}
  text-transform: uppercase;
  animation: ${Animation} 1s ease-in-out 0s 1;
`;

// const WrapperForBG = styled.img.attrs({
//   src: (props: ImgProps) => props.src ? props.src : ''
// })<ImgProps>`
//   position: absolute;
//   height: 130%;
//   margin-top: -15%;
//   margin-left: -17%;
//   ${flexCSS('row', 'flex-start', 'center')}
// `;

const WrapperForTitle = styled.div`
  text-align: right;
  font-size: 35px;
  font-weight: 700;
  width: 750;
  margin-right: -120;
  max-width: 50%;
  position: relative;
  @media ${device.laptopM} {
    max-width: 80%;
  }
`;

const Title = styled.div`
  @media ${device.mobile} {
    font-size: 29px;
  }
  @media ${device.mobileS} {
    font-size: 25px;
  }
  
`;

const SubTitle = styled.div`
  padding-top: 5;
  font-size: 23px;
  font-weight: 700;
  @media ${device.mobile} {
    font-size: 19px;
  }
  @media ${device.mobileS} {
    font-size: 16px;
  }
`;