import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import { flexCSS } from '../../assets/FitCSS';
import Member, { IMember } from './Member';
import LanguageContext from '../../contexts/Language';
import { Title as T, JSON_Crew } from './JSON';
import { device } from '../../styled-components/MediaQueries';

import { Button } from '../Portfolio/PortfolioWrapper';

const CrewWrapper: React.FC<{
  style?: any,
}> = () => {
  const { language } = useContext(LanguageContext);
  const [activeButton, setActiveButton] = useState<'mobile' | 'web'>('mobile');

  return (
    <ScrollableAnchor id={'crew'}>
      <Wrapper>
        <SubWrapper>
          <Title>{T.title[language]}</Title>
          <CButtons>
            {/* <Button onClick={() => setActiveButton('web')} active={activeButton === 'web'}>Web</Button> */}
            <Button onClick={() => setActiveButton('mobile')} active={activeButton === 'mobile'}>Mobile</Button>
          </CButtons>
          <Crew>
            {language ? JSON_Crew[activeButton][language].map((member: IMember) => <Member key={member.id} member={member}>{member.name}</Member>) : null}
          </Crew>
        </SubWrapper>
      </Wrapper>
    </ScrollableAnchor>

  );
}

export default CrewWrapper;

const Title = styled.div`
  color: var(--theme-title-color);
  margin-left: 30px;
  font-size: 20pt;
  font-weight: 700;
  text-align: start;
  width: 100%;
  margin-bottom: 30px;
  @media ${device.mobileC} {
    text-align: center;
    margin-left: 0px;
  }
`;

const Wrapper = styled.div`
  padding-top: 50px;
  transition: 0.3s;
  width: 100%;
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  padding-bottom: 50px;
  ${flexCSS('row')}
`;

const CButtons =
  styled.div<{ style?: any }>`
  ${flexCSS('row', 'center', 'flex-start')}
  width: 100%;
  margin-bottom: 40px;
  margin-left: 30px;
  ${(props: { style?: any }) => props.style ? props.style : null}
  @media ${device.mobileC} {
     justify-content: center;
     margin-left: 0px; 
     }
`;

const SubWrapper = styled.div`
  width: 65%;
  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.mobileC} {
    width: 95%;
  }
`;

const Crew = styled.div`
  width: 100%;
  ${flexCSS('row', 'center', 'space-between')}
  flex-wrap: wrap;
  margin-top: 30px;
`;