import React from 'react';
import styled from 'styled-components';

import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';

const Step: React.FC<{
  index: string, title: string, style?: any
}> = ({index, title, style, children}) => {
  return (
    <Wrapper>
      <Title>/{index}</Title><Description>{title}</Description>
    </Wrapper>
    );
  }
  
export default Step;

const Title = styled.div`
  transition: 0.3s;
  font-weight: 700;
  font-size: 40pt;
  color: var(--theme-step-color);
  margin-right: 10px;
  @media ${device.mobile} {
    color: var(--c-orange);
    font-size: 30pt;
  }
`;

const Wrapper = styled.div`
  position: relative;
  transition: 0.3s;
  width: 400px;
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  padding-bottom: 50px;
  ${flexCSS('row', 'center', 'flex-start')}
  &:hover {
    box-shadow: 0 0 8px var(--c-orange);
  }
  &:hover ${Title} {
    color: var(--c-orange);
  }
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  @media ${device.mobile} {
    width: 90%;
    box-shadow: 0 0 8px var(--c-orange);
  }
`;

const Description = styled.div`
  text-align: left;
  font-size: 9pt;
  font-weight: 700;
`;