interface IServices {
  web: {
    [language: string]: string[]
  },
  mobile: {
    [language: string]: string[]
  }
}

export const Services: IServices = {
  web: {
    ru: [
      'Разработка сайтов, интернет-магазинов, лендингов', 
      'Разработка индивидуального функционала',
      'Web-дизайн, создание логотипов',
      'Техническое и информационное сопровождение'
    ],
    ua: [
      'Розробка сайтів, інтернет-магазинів, лендінгів',
      'Розробка індивідуального функціоналу',
      'Web-дизайн, створення логотипів',
      'Технічний та інформаційний супровід проектів'
    ],
    en: [
      'Development of sites, online stores, landing pages',
      'Development of individual functionality',
      'Web-design, logo creation',
      'Technical and informational support'
    ]
  },
  mobile: {
    ru: [
      'Разработка нативных мобильных приложений iOS, Android', 
      'Создание кросплатформенных мобильных приложений',
      'UI/UX дизайн',
      'Техническое сопровождение проектов'
    ],
    ua: [
      'Розробка нативних мобільних додатків iOS, Android',
      'Створення кросплатформних мобільних додатків',
      'UI/UX дизайн',
      'Технічний супровід проектів'
    ],
    en: [
      'Development of native mobile applications iOS, Android',
      'Creation of cross-platform mobile applications',
      'UI/UX design',
      'Technical support of projects'
    ]
  }
}