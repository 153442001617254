import React from 'react';
import styled, { keyframes } from 'styled-components';
import { device } from '../styled-components/MediaQueries';

const Icon: React.FC<{
  src: string, alt: string, style?: any, onClick?: Function
}> = ({
  src, alt, children, style, onClick
}) => {
  const Icon = styled.img`
    ${style}
    cursor: pointer;
  `;
  return (
    <Icon onClick={() => onClick ? onClick() : console.warn('')} src={src} alt={alt} />
  )
}

export default Icon;

export const styleGeneralIcon = {
  height: 54
}

export const styleFullGeneralIcon = {
  height: 23,
  'margin-left': 25,
  'padding-bottom': 8
}

const defaultStyle = `
  position: fixed;
  bottom: 50px;
  z-index: 100;
  height: 45px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    height: 50px;
  }
`;

const AnimationLeft = keyframes`
  0% {
    left: -100%;
    opacity: 0;
  }
  100% {
    left: 50px;
    opacity: 1;
  }
`;

const AnimationRight = (right: number) => keyframes`
  0% {
    right: -100%;
    opacity: 0;
  }
  100% {
    right: ${right}px;
    opacity: 1;
  }
`;

export const SideLeftIcon = styled.img`
  ${defaultStyle}
  left: 50px;
  &:hover {
    transform: rotate(10deg);
  }
  @media ${device.mobileC} {
    display: none;
  }
  animation: ${AnimationLeft} 1.3s ease-in-out 0s 1;
`;

export const SideRightIcon = styled.img`
  ${defaultStyle}
  right: 50px;
  animation: ${AnimationRight(50)} 1.7s ease-in-out 0s 1;
  @media ${device.mobileC} {
    height: 30px;
    right: 20px;
    animation: ${AnimationRight(20)} 1.7s ease-in-out 0s 1;
    &:hover {
      height: 35px;
    }
  }
`;