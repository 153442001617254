import React from 'react';
import styled from 'styled-components';

import Text from '../Text';
import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';

const Service: React.FC<{
  items: string[], title: string, image: string, style?: any
}> = ({ items, title, image }) => {

  return (
    <Wrapper>
      <Image id={title} src={require(`../../assets/images/icons/${image}.png`)} alt={title}/>
      <Text  style={{'padding': '20px', 'font-size':'16pt', 'font-weight': '700'}}>{title}</Text>
      <WrapperList>
        {
          items.map((item, index) => 
            <WrapperForItems key={title+'_key'+index}>
              <Text style={{color: 'var(--c-orange)', 'font-weight': '700', 'font-size': '10pt'}}>></Text>
                &nbsp;&nbsp;
              <Text style={{'font-size': '9pt', 'text-align': 'left','font-weight': '600',}}>{item}</Text>
            </WrapperForItems>
          )
        }
      </WrapperList>
    </Wrapper>
    );
  }
  
export default Service;
  
const Wrapper = styled.div`
  cursor: pointer;
  background: var(--theme-bg2-color);
  color: var(--theme-font-color);
  margin: 10px;
  width: 350px;
  border-radius: 15px;
  background-color: var(--theme-bg-color);
  ${flexCSS('column', 'center')}
  padding: 30px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 0 8px var(--c-orange);
  }
  @media ${device.mobile} {
    box-shadow: 0 0 12px var(--c-orange);
    width: 80%;
    padding: 20px;
    margin-bottom: 15px;
  }
`;

const Image = styled.img`
  height: 180px;
  transition: 1.5s;
  backface-visibility: hidden;
  transform-origin: center bottom;
  @media ${device.mobile} {
    display: none;
  }
`;

const WrapperForItems = styled.div`
  padding-bottom: 5px;
  ${flexCSS('row', 'center', 'flex-start')}
`;

const WrapperList = styled.div`
  align-self: self-end;
  width: 100%;
  min-width: 290px;
  ${flexCSS('column', 'flex-start', 'flex-start')}
  @media ${device.laptopM} {
    width: 100%;
    min-width: 200px;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;