import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';

import { flexCSS } from '../../assets/FitCSS';
import LanguageContext from '../../contexts/Language';
import Step from './Step';
import { JSON_steps } from './json.steps';
import { device } from '../../styled-components/MediaQueries';

interface IDefaultStepProps {
  visible: boolean,
  type?: 'left' | 'right',
  other: {
    duration: number,
    delay: number,
  }
}

interface IAnimation {
  [step: string]: {
    delay: number
  }
}

const Steps: React.FC<{visible: boolean}> = ({visible}) => {
  const duration: number = 0.5;
  const settings: IAnimation = {
    s1: { delay: duration * 0 },
    s2: { delay: duration * 1 },
    s3: { delay: duration * 2 },
    s4: { delay: duration * 3 },
    s5: { delay: duration * 4 },
    s6: { delay: duration * 5 },
  };

  const { language } = useContext(LanguageContext);
  
  return (
    <WrapperForSteps>
      <Couple1>
        <Left visible={visible} type={'left'} other={{ duration, delay: settings.s1.delay }}>
          <Step key={JSON_steps[0].index} index={JSON_steps[0].index} title={JSON_steps[0].title[language]} />
        </Left>
        <Right visible={visible} type={'right'} other={{ duration, delay: settings.s4.delay }}>
          <Step key={JSON_steps[3].index} index={JSON_steps[3].index} title={JSON_steps[3].title[language]} />
        </Right>
      </Couple1>

      <Couple2>
        <Left visible={visible} type={'left'} other={{ duration, delay: settings.s2.delay }}>
          <Step key={JSON_steps[1].index} index={JSON_steps[1].index} title={JSON_steps[1].title[language]} />
        </Left>
        <Right visible={visible} type={'right'} other={{ duration, delay: settings.s5.delay }}>
          <Step key={JSON_steps[4].index} index={JSON_steps[4].index} title={JSON_steps[4].title[language]} />
        </Right>
      </Couple2>

      <Couple3>
        <Left visible={visible} type={'left'} other={{ duration, delay: settings.s3.delay }}>
          <Step key={JSON_steps[2].index} index={JSON_steps[2].index} title={JSON_steps[2].title[language]} />
        </Left>
        <Right visible={visible} type={'right'} other={{ duration, delay: settings.s6.delay }}>
          <Step key={JSON_steps[5].index} index={JSON_steps[5].index} title={JSON_steps[5].title[language]} />
        </Right>
      </Couple3>

      <DefaultMultipleCouple>
        {
          JSON_steps.map((step, index) => <SteppingAnimation key={index} visible={visible} other={{ duration, delay: settings[`s${Number(step.index)}`].delay }}>
              <Step index={step.index} title={step.title[language]} />
            </SteppingAnimation>
          )
        }
      </DefaultMultipleCouple>
    </WrapperForSteps>
    );
  }
  
export default Steps;

const WrapperForSteps = styled.div`
  width: 100%;
  ${flexCSS('column')}
`;

const DefaultMultipleCouple = styled.div`
  ${flexCSS('column')}
  display: none;
  width: 100%;
  @media ${device.laptopM} {
    display: flex;
  }
`;

const defaultCouple = styled.div`
  position: relative;
  width: 880px;
  height: 104px;
  ${flexCSS('row')}
  @media ${device.laptopM} {
    display: none;
  }
`;

const Couple1 = styled(defaultCouple)`
  margin-left: -200px;
  @media ${device.laptop} {
    margin-left: -100px;
  }
  @media ${device.laptop} {
    margin-left: 0px;
  }
`;

const Couple2 = styled(defaultCouple)` 
`;

const Couple3 = styled(defaultCouple)`
  margin-left: 200px;
  @media ${device.laptopX} {
    margin-left: 100px;
  }
  @media ${device.laptop} {
    margin-left: 100px;
  }
  @media ${device.laptop} {
    margin-left: 0px;
  }
`;

const defaultStep = styled.div<IDefaultStepProps>`
  position: absolute;
  animation: 
    ${props => !props.visible ? Empty : Animation(props.type ? props.type : 'left')}
    ${props => props.other && props.other.duration}s ease-in-out 
    ${props => props.other && props.other.delay}s 1;
  animation-fill-mode: forwards;
  opacity: 0;
`;

const defaultResizeStep = styled.div<IDefaultStepProps>`
  animation: 
    ${props => !props.visible ? Empty : AnimationResize()}
    ${props => props.other && props.other.duration}s ease-in-out 
    ${props => props.other && props.other.delay}s 1;
  animation-fill-mode: forwards;
  opacity: 0;
`;

const SteppingAnimation = styled(defaultResizeStep)`
  width: 100%;
  ${flexCSS('row')}
`;

const Left = styled(defaultStep)`
  left: -100%;
  z-index: 20;
`;
 
const Right = styled(defaultStep)`
  right: 430px;
  z-index: 10;
`;

const Animation = (type: 'left' | 'right') => {
  return keyframes`
    0% {
      ${type === 'left' ? 'left: -100%;' : 'right: 430px;'}
      opacity: 0;
    }
    100% {
      ${type === 'left' ? 'left: 0;' : 'right: 0;'}
      opacity: 1;
    }
  `;
}

const AnimationResize = () => {
  return keyframes`
    0% {
      margin-left: -100%;
      opacity: 0;
    }
    100% {
      mergin-left: 0;
      opacity: 1;
    } 
  `;
}

const Empty = keyframes``;