import React, { useContext } from 'react';
import styled from 'styled-components';

import { flexCSS } from '../../assets/FitCSS';
import LanguageContext from '../../contexts/Language';
import { Arrow } from '../StepsWorking/Form';
import { Bespeak } from './JSON';
import { device } from '../../styled-components/MediaQueries';
import { onClickUrl } from '../Products/helpers';

const NotificationForm: React.FC<{
  style?: any,
}> = ({ style, children }) => {
  const { language } = useContext(LanguageContext);
  return (
    <Wrapper>
      <Title>{Bespeak.Notification.attention[language]}</Title>
      <Description>{Bespeak.Notification.description[language]}</Description>
      <CButton onClick={onClickUrl(Bespeak.Notification.url[language])}>{Bespeak.Notification.buttonText[language]}<Arrow /></CButton>
    </Wrapper>
  )
}

export default NotificationForm;

const Wrapper = styled.div`
  width: 100%;
  ${flexCSS('column', 'flex-start')}
  color: var(--theme-font-color);
  @media ${device.mobileC} {
    margin-top: 30px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14pt;
  width: 100%;
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 9pt;
  width: 100%;
`;

const CButton = styled.button`
  width: 70%;
  margin: 30px 15% 0px;
  color: white;
  border: 1px solid var(--c-orange);
  padding: 10px;
  ${flexCSS('row')}
  border-radius: 5px;
  background: var(--c-orange);
  outline: none;
  --theme-form-button-color: white;
  &:focus {
    outline: none;
  }
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: none;
    box-shadow: var(--theme-form-button-shadow);
    color: var(--theme-from-button-brief-color);
    --theme-form-button-color: var(--theme-from-button-brief-color);
  }
  @media ${device.mobileM} {
    width: 82%;
    font-size: 8pt;
    margin: 30px 8% 0px;
  }
`;