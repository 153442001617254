export const JSON_steps: {
  index: string,
  title: {[name: string]: string}
}[] = [
  {
    index: '01',
    title: {
      'ru': 'Анализ рынка конкурентных предложений, изучение требований',
      'ua': 'Аналіз ринку конкурентних пропозицій, вивчення вимог',
      'en': 'Market analysis of competitive offers, the study of requirements'
    }
  },
  {
    index: '02',
    title: {
      'ru': 'Разработка технической документации на реализацию проекта, проектирование архитектуры',
      'ua': 'Розробка технічної документації на реалізацію проекту, проектування архітектури',
      'en': 'Development of technical documentation for the implementation of the project, architecture design'
    }
  },
  {
    index: '03',
    title: {
      'ru': 'Прототипирование, UI/UX дизайн',
      'ua': 'Прототипування, UI/UX дизайн',
      'en': 'Prototyping, UI/UX Design'
    }
  },
  {
    index: '04',
    title: {
      'ru': 'Frontend и backend разработка',
      'ua': 'Frontend і backend розробка',
      'en': 'Frontend and backend development'
    }},
  {
    index: '05',
    title: {
      'ru': 'Работа с информационной частью проекта: контент, оптимизация',
      'ua': 'Робота з інформаційної частиною проекту: контент, оптимізація',
      'en': 'Work with the informational part of the project: content, optimization'
    }},
  {
    index: '06',
    title: {
      'ru': 'Тестирование и запуск проекта',
      'ua': 'Тестування і запуск проекту',
      'en': 'Testing and launching a project'
    }
  }
];