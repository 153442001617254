import React from 'react';
import styled from 'styled-components';
import { flexCSS } from '../../assets/FitCSS';
import LanguageContext from '../../contexts/Language';
import { Products } from './JSON';
import { Arrow } from '../StepsWorking/Form';
import { device } from '../../styled-components/MediaQueries';
import { onClickUrl } from './helpers';


const RestupWrapper: React.FC<{
    style?: any,
}> = ({ style, children }) => {
    const { language } = React.useContext(LanguageContext);

    return (
        <Wrapper>
            <SubWrapper>
                <LeftSide>
                    <Logo> <Image src={require(`../../assets/images/products/logo1.png`)} /></Logo>
                    <Title>Restup</Title>
                    <Title2>{Products.TitleRest[language]}</Title2>
                    <Paragraph>{Products.PRest[language]}</Paragraph>
                    <ButtonLink onClick={onClickUrl('https://restup.kitapp.pro/')}>{Products.ButtonText[language]}<Arrow /></ButtonLink>
                </LeftSide>
                <RightSide>
                    <ImageWrapper><Image src={require(`../../assets/images/products/restup1.png`)} /></ImageWrapper>
                    <ImageWrapper><Image src={require(`../../assets/images/products/restup2.png`)} /></ImageWrapper>
                </RightSide>
            </SubWrapper>
        </Wrapper>
    )
}

export default RestupWrapper;

const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg2-color);
  ${flexCSS('row')}
  color: var(--theme-font-color);
`;


export const SubWrapper = styled.div`
  width: 60%;
  padding:30px 0 50px 0;
  ${flexCSS('row')};
  justify-content:space-between;
  gap: 30px;
  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.laptopM} {
    ${flexCSS('column')}
  }
  @media ${device.mobileC} {
    width: 95%;
    padding-bottom:50px;
  }
`;

export const Title = styled.div`
  color: var(--theme-font-color);
  text-align: start;
  font-size: 20pt;
  font-weight: 700;
  @media ${device.mobileC} {
    text-align: center;
    width: 100%;
    margin-left: 0px;
  }
`;
export const Title2 = styled.div`
  color: var(--theme-font-color);
  text-align: start;
  margin: 20px 0 30px 0;
  font-size: 13.5pt;
  font-weight: 700;
  @media ${device.mobileC} {
    text-align: center;
    margin: 10px 0px;
    width: 100%;
  }
  
`;
export const Paragraph = styled.div`
  text-align: left;
  font-size: 9pt;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 17px;
  color: var(--theme-input-color);
  @media ${device.mobileC} {
    margin: 0;
    width: 95%;
    text-indent: 4pt;
  }
`;

export const LeftSide = styled.div`
  display:flex;
  width: 50%;
  ${flexCSS('column', 'flex-start')}
  @media ${device.laptop} {
    height: 450px;
  }
  @media ${device.laptop} {
    width: 100%;
    height: fit-content;
  }
`;

export const RightSide = styled.div`
  display:flex;
  width: 50%;
   @media ${device.laptop} {
      width: 70%;
  }
   @media ${device.mobileL} {
      width: 100%;
  }
  
`;
export const ImageWrapper = styled.div`
   display:flex;
   flex-shrink:1;
   
`
export const Image = styled.img`
  max-width:100%;
  height:auto;
`;

export const Logo = styled.div`
  width:66px;
  height:40px;
  margin-bottom:20px;
  @media ${device.mobileC} {
      margin: 30px auto 10px auto;
  }
`;

export const ButtonLink = styled.button`
  width:202px;
  height:37px;
  font-size:10pt;
  margin-top:30px;
  color: white;
  border: 1px solid var(--c-orange);
  padding: 10px;
  ${flexCSS('row')}
  border-radius: 5px;
  background: var(--c-orange);
  outline: none;
  --theme-form-button-color: white;
  &:focus {
    outline: none;
  };
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: none;
    box-shadow: var(--theme-form-button-shadow);
    color: var(--theme-from-button-brief-color);
    --theme-form-button-color: var(--theme-from-button-brief-color);
  }
  @media ${device.mobileC} {
    width: 200px;
    margin: 20px auto 0 auto;
  }
`
