import { IMember } from './Member';

export interface ITitle {
  title: {
    [language: string]: string
  }
}

export const Title: ITitle = {
  title: {
    ru: 'Команда',
    ua: 'Команда',
    en: 'Crew'
  }
}

interface ICrew {
  web: {
    [language: string]: IMember[]
  },
  mobile: {
    [language: string]: IMember[]
  }
}

export const JSON_Crew: ICrew = {
  web: {
    ua: [
      {
        id: 1,
        name: 'Олексій',
        post: 'Директор',
        avatar: '-w-1',
        linkLinkedin:'https://www.linkedin.com/in/alexey-fedorenko-7683a668/'
      },
      {
        id: 2,
        name: 'Світлана',
        post: 'Керівник Web відділу',
        avatar: '-w-2',
        linkLinkedin: ' '
      },
      {
        id: 3,
        name: 'Ірина',
        post: 'Дизайнер',
        avatar: '-w-3',
        linkLinkedin: ' '
      },
      {
        id: 4,
        name: 'Сергій',
        post: 'SEO фахівець',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 5,
        name: 'Олександр',
        post: 'Веб-майстер',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 6,
        name: 'Володимир',
        post: 'Дизайнер',
        avatar: '-w-5',
        linkLinkedin: ' '
      }
    ],
    en: [
      {
        id: 1,
        name: 'Alexey',
        post: 'Director',
        avatar: '-w-1',
        linkLinkedin: 'https://www.linkedin.com/in/alexey-fedorenko-7683a668/'
      },
      {
        id: 2,
        name: 'Svetlana',
        post: 'Head of Web Department',
        avatar: '-w-2',
        linkLinkedin: ' '
      },
      {
        id: 3,
        name: 'Irina',
        post: 'Designer',
        avatar: '-w-3',
        linkLinkedin: ' '
      },
      {
        id: 4,
        name: 'Sergey',
        post: 'SEO specialist',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 5,
        name: 'Alexandr',
        post: 'Webmaster',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 6,
        name: 'Vladimir',
        post: 'Designer',
        avatar: '-w-5',
        linkLinkedin: ' '
      }
    ],
    ru: [
      {
        id: 1,
        name: 'Алексей',
        post: 'Директор',
        avatar: '-w-1',
        linkLinkedin: 'https://www.linkedin.com/in/alexey-fedorenko-7683a668/'
      },
      {
        id: 2,
        name: 'Светлана',
        post: 'Руководитель Web отдела',
        avatar: '-w-2',
        linkLinkedin: ' '
      },
      {
        id: 3,
        name: 'Ирина',
        post: 'Дизайнер',
        avatar: '-w-3',
        linkLinkedin: ' '
      },
      {
        id: 4,
        name: 'Сергей',
        post: 'SEO специалист',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 5,
        name: 'Александр',
        post: 'Веб-мастер',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 6,
        name: 'Владимир',
        post: 'Дизайнер',
        avatar: '-w-5',
        linkLinkedin: ' '
      }
    ]
  },
  mobile: {
    ua: [
      {
        id: 1,
        name: 'Олексій',
        post: 'Директор СЕО',
        avatar: '-m-1',
        linkLinkedin: 'https://www.linkedin.com/in/alexey-fedorenko-7683a668/'
      },
      {
        id: 2,
        name: 'Олександр',
        post: 'Керівник Mobile відділу СТО',
        avatar: '-m-4',
        linkLinkedin: 'https://www.linkedin.com/in/aleksparkhomenko/'
      },
      {
        id: 3,
        name: 'Захар',
        post: 'Frontend розробник Teamlead',
        avatar: '-m-3',
        linkLinkedin: ' '
      },
      {
        id: 4,
        name: 'Ярослав',
        post: 'Керівник проектів',
        avatar: '-m-8',
        linkLinkedin: ' '
      },
      {
        id: 5,
        name: 'Ярослав',
        post: 'QA спеціаліст',
        avatar: '-m-8',
        linkLinkedin: ' '
      },
      {
        id: 6,
        name: 'Віктор',
        post: 'Front-end React/React Native розробник',
        avatar: '-w-5',
        linkLinkedin: ' '
      },
      {
        id: 7,
        name: 'Слава',
        post: 'Front-end React Native розробник',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 8,
        name: 'Микола',
        post: 'Front-end React/React Native розробник',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 9,
        name: 'Юрій',
        post: 'Front-end розробник',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 10,
        name: 'Андрій',
        post: 'Lead UX/UI дизайнер',
        avatar: '-m-3',
        linkLinkedin: ' '
      },
      {
        id: 11,
        name: 'Дмитро',
        post: 'Front-end React Native розробник',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 12,
        name: 'Артем',
        post: 'Lead back-end Node.js розробник',
        avatar: '-w-5',
        linkLinkedin: ' '
      },
      {
        id: 13,
        name: 'Роман',
        post: 'Back-end Node.js розробник',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 14,
        name: 'Артем',
        post: 'Lead marketing',
        avatar: '-m-4',
        linkLinkedin: ' '
      },
      {
        id: 15,
        name: 'Наталія',
        post: 'Front-end розробник',
        avatar: '-w-2',
        linkLinkedin: ' '
      },
      {
        id: 16,
        name: 'Аліна',
        post: 'HR-manager',
        avatar: '-w-2',
        linkLinkedin: 'https://www.linkedin.com/in/alina-harkavenko-44362220b/'
      },
      {
        id: 17,
        name: 'Тетяна',
        post: 'Sales manager',
        avatar: '-w-3',
        linkLinkedin:' '
      },
      {
        id: 18,
        name: 'Андрій',
        post: 'Devops інженер',
        avatar: '-m-4',
        linkLinkedin: ' '
      }
    ],
    en: [
      {
        id: 1,
        name: 'Alexey',
        post: 'CEO',
        avatar: '-m-1',
        linkLinkedin: 'https://www.linkedin.com/in/alexey-fedorenko-7683a668/'
      },
      {
        id: 2,
        name: 'Alexandr',
        post: 'CTO',
        avatar: '-m-4',
        linkLinkedin: 'https://www.linkedin.com/in/aleksparkhomenko/'
      },
      {
        id: 3,
        name: 'Zahar',
        post: 'Teamlead',
        avatar: '-m-3',
        linkLinkedin: ' '
      },
      {
        id: 4,
        name: 'Iaroslav',
        post: 'Project Manager',
        avatar: '-m-8',
        linkLinkedin: ' '
      },
      {
        id: 5,
        name: 'Iaroslav',
        post: 'QA specialist',
        avatar: '-m-8',
        linkLinkedin: ' '
      },
      {
        id: 6,
        name: 'Victor',
        post: 'Front-end React/React Native dev',
        avatar: '-w-5',
        linkLinkedin: ' '
      },
      {
        id: 7,
        name: 'Slava',
        post: 'Front-end React Native dev',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 8,
        name: 'Mykola',
        post: 'Front-end React/React Native dev',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 9,
        name: 'Yuriy',
        post: 'Front-end dev',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 10,
        name: 'Andrii',
        post: 'Lead UX/UI designer',
        avatar: '-m-3',
        linkLinkedin: ' '
      },
      {
        id: 11,
        name: 'Dmytro',
        post: 'Front-end React Native dev',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 12,
        name: 'Artem',
        post: 'Lead back-end Node.js dev',
        avatar: '-w-5',
        linkLinkedin: ' '
      },
      {
        id: 13,
        name: 'Roman',
        post: 'Back-end Node.js dev',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 14,
        name: 'Artem',
        post: 'Lead marketing',
        avatar: '-m-4',
        linkLinkedin: ' '
      },
      {
        id: 15,
        name: 'Nataliia',
        post: 'Front-end dev',
        avatar: '-w-2',
        linkLinkedin: ' '
      },
      {
        id: 16,
        name: 'Alina',
        post: 'HR-manager',
        avatar: '-w-2',
        linkLinkedin:'https://www.linkedin.com/in/alina-harkavenko-44362220b/'
      },
      {
        id: 17,
        name: 'Tetyana',
        post: 'Sales manager',
        avatar: '-w-3',
        linkLinkedin: ' '
      },
      {
        id: 18,
        name: 'Andrii',
        post: 'Devops engineer',
        avatar: '-m-4',
        linkLinkedin: ' '
      }
    ],
    ru: [
      {
        id: 1,
        name: 'Алексей',
        post: 'CEO',
        avatar: '-m-1',
        linkLinkedin: "https://www.linkedin.com/in/alexey-fedorenko-7683a668/"
      },
      {
        id: 2,
        name: 'Александр',
        post: 'CTO',
        avatar: '-m-4',
        linkLinkedin: "https://www.linkedin.com/in/aleksparkhomenko/"
      },
      {
        id: 3,
        name: 'Захар',
        post: 'Teamlead',
        avatar: '-m-3',
        linkLinkedin: ' '
      },
      {
        id: 4,
        name: 'Ярослав',
        post: 'Project Manager',
        avatar: '-m-8',
        linkLinkedin: ' '
      },
      {
        id: 5,
        name: 'Ярослав',
        post: 'QA специалист',
        avatar: '-m-8',
        linkLinkedin: ' '
      },
      {
        id: 6,
        name: 'Виктор',
        post: 'Front-end React/React Native разработчик',
        avatar: '-w-5',
        linkLinkedin: ' '
      },
      {
        id: 7,
        name: 'Слава',
        post: 'Front-end React Native разработчик',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 8,
        name: 'Николай',
        post: 'Front-end React/React Native разработчик',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 9,
        name: 'Юрий',
        post: 'Front-end разработчик',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 10,
        name: 'Андрей',
        post: 'Lead UX/UI дизайнер',
        avatar: '-m-3',
        linkLinkedin: ' '
      },
      {
        id: 11,
        name: 'Дмитрий',
        post: 'Front-end React Native разработчик',
        avatar: '-w-6',
        linkLinkedin: ' '
      },
      {
        id: 12,
        name: 'Артём',
        post: 'Lead back-end Node.js разработчик',
        avatar: '-w-5',
        linkLinkedin: ' '
      },
      {
        id: 13,
        name: 'Роман',
        post: 'Back-end Node.js разработчик',
        avatar: '-w-4',
        linkLinkedin: ' '
      },
      {
        id: 14,
        name: 'Артём',
        post: 'Lead marketing',
        avatar: '-m-4',
        linkLinkedin: ' '
      },
      {
        id: 15,
        name: 'Наталия',
        post: 'Front-end разработчик',
        avatar: '-w-2',
        linkLinkedin: ' '
      },
      {
        id: 16,
        name: 'Алина',
        post: 'HR-manager',
        avatar: '-w-2',
        linkLinkedin: 'https://www.linkedin.com/in/alina-harkavenko-44362220b/'
      },
      {
        id: 17,
        name: 'Татьяна',
        post: 'Sales manager',
        avatar: '-w-3',
        linkLinkedin: ' '
      },
      {
        id: 18,
        name: 'Андрей',
        post: 'Devops инженер',
        avatar: '-m-4',
        linkLinkedin: ' '
      }
    ]
  }
}