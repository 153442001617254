import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import { flexCSS } from '../../assets/FitCSS';
import { MobileSlides, ButtonText, WebSlides, JSON_list, ISlides, ISlider } from './json.slider';
import LanguageContext, { TranslatorObject } from '../../contexts/Language';
import MobSlider from './MobSlider';
import WebSlider from './WebSlider';
import { device } from '../../styled-components/MediaQueries';
import ModalOrder from '../Greeting/ModalOrder';
import { ButtonLink } from '../Products/RestupWrapper';

interface ILinkProps {
  href?: string;
}

const PortfolioWrapper: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const [activeButton, setActiveButton] = useState<'mobile' | 'web'>('mobile');
  const [slides, setSlides] = useState<ISlider[]>(activeButton === 'mobile' ? MobileSlides['kuchenchef'] : WebSlides['site1']);
  const [orderModal, setOrderModal] = useState(false);

  const handleActiveType = (type: 'web' | 'mobile') => {
    setSlides(type === 'web' ? WebSlides.site1 : MobileSlides.kuchenchef);
    setActiveButton(type);
  }

  return (
    <ScrollableAnchor id={'portfolio'}>
      <Wrapper>
        <SubWrapper>
          <LeftSide platform={activeButton}>
            <Title>{TranslatorObject.Portfolio.title[language]}</Title>
            <Buttons>
              {/* <Button onClick={() => handleActiveType('web')} active={activeButton === 'web'}>Web</Button> */}
              <Button onClick={() => handleActiveType('mobile')} active={activeButton === 'mobile'}>Mobile</Button>
            </Buttons>
            <List platform={activeButton}>
              {
                JSON_list[activeButton].map((item: ISlides) => {
                  if (activeButton === 'mobile') {
                    return <Li onClick={() => setSlides(MobileSlides[item.slug.name])} key={item.id}>{item.title[language]}</Li>
                  }
                  else {
                    return <Li onClick={() => setSlides(WebSlides[item.slug.name])} key={item.id}>{item.title[language]}</Li>
                  }

                })
              }
            </List>
            {
              activeButton === 'mobile'
                ? <Link target='_blank' href="https://kitapp.pro/uk/portfolyo/">{TranslatorObject.Portfolio.link[language]}</Link>
                : <Link target='_blank' href="https://kitweb.pro/">{TranslatorObject.Portfolio.linkWeb[language]}</Link>
            }
            <ButtonLink onClick={() => setOrderModal(true)}>{ButtonText.text[language]}<Arrow style={{ height: '100%', padding: '8px 5px 5px' }} /></ButtonLink>
          </LeftSide>
          <RightSide>
            {
              activeButton === 'mobile'
                ? <>
                  <MobSlider slides={slides} />
                </>
                : <>
                  <WebSlider slides={slides} />
                  <Icon src={require('../../assets/images/slider/pc-bottom.png')} alt="pc" />
                </>
            }
          </RightSide>
        </SubWrapper>
        {orderModal ? <ModalOrder switch={() => setOrderModal(false)} /> : null}
      </Wrapper>
    </ScrollableAnchor>
  )
}

export default PortfolioWrapper;

const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  padding-bottom: 50px;
  ${flexCSS('row')}
  
`;

const SubWrapper = styled.div`
  width: 60%;
  ${flexCSS('row', 'flex-start', 'space-between')}
  @media ${device.laptop} { width: 85%; }
  @media ${device.laptopM} { 
    ${flexCSS('column')}
  }
  @media ${device.mobileC} { width: 95%; }
`;

const LeftSide = styled.div<{ platform?: 'web' | 'mobile' }>`
  width: 410px;
  min-width: 355px;
  ${flexCSS('column', 'flex-start')}
  @media ${device.laptopM} {
    width: 60%;
    min-width: 200px;
  }
  @media ${device.mobileC} {
    align-items: center;
    justify-content: center;
    ${props => props.platform === 'web' ? 'width: 90%;' : null}
  }
`;

const RightSide = styled.div`
  width: 564px;
  min-width: 355px;
  margin-top: 40px;
  ${flexCSS('column')}
  @media ${device.laptopM} {
    min-width: 200px;
    width: 60%;
  }
  @media ${device.laptopM} {
     ${flexCSS('column')}
     }
`;

const Title = styled.div`
  padding: 30px 0px;
  font-size: 20pt;
  font-weight: 700;
  text-align: start;
  width: 65%;
  color: var(--theme-title-color);
  @media ${device.mobileC} { text-align: center; }
`;

export const Buttons = styled.div<{ style?: any }>`
  ${flexCSS('row', 'center', 'flex-start')}
  width: 100%;
  margin-bottom: 40px;
  ${(props: { style?: any }) => props.style ? props.style : null}
  @media ${device.mobileC} { justify-content: center; }
`;

export const Button = styled.div<{ active: boolean }>`
  width: 125px;
  height: 35px;
  margin-right: 10px;
  ${flexCSS('row', 'center', 'center')}
  transition: 0.3s;
  border: 1px solid var(--c-orange);
  border-radius: 7px;
  cursor: pointer;
  ${props => props.active
    ? 'color: var(--theme-portfolio-button-active-color); background-color: var(--c-orange);'
    : 'color: var(--theme-portfolio-button-color); background-color: var(--theme-bg-color);'
  }
  &:hover {
    box-shadow: 0 0 8px var(--c-orange);
  }
`;

// const OrderButton = styled(Button)`
//   width: 200px;
//   font-size: 10pt;
//   margin-top: 20px;
// `;

const List = styled.div<{ platform?: 'web' | 'mobile' }>`
  width: ${props => props.platform === 'web' ? '80%' : '100%'};
  ${flexCSS('column', 'flex-start', 'flex-start')}
  @media ${device.laptopM} {
    width: 100%;
  }
`;

const Li = styled.div`
  transition: 0.3s;
  text-align: left;
  font-size: 10pt;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  &:hover {
    border-bottom: 1px solid var(--c-orange);
  }
`;

const Link = styled.a<ILinkProps>`
  text-decoration: none;
  color: var(--theme-p-link-color);
  font-size: 9pt;
  font-weight: 700;
  text-decoration: underline
`;

const Icon = styled.img`
  width: 564px;
  @media ${device.laptopX} {
    width: 452px;
  }
  @media ${device.laptopM} {
    width: 452px;
  }
  @media ${device.mobileL} {
    width: 400px;
  }
  @media ${device.mobile} {
    width: 370px;
  }
  @media ${device.mobileM} {
    width: 340px;
  }
  @media ${device.mobileS} {
    width: 310px;
  }
`;


const Arrow: React.FC<{ style?: any }> = ({ style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 10" width="12" height="9" style={{ padding: '0px 5px', ...style }}>
      <g id="Группа 1">
        <path id="Фигура 1 копия 5" style={{ fill: 'white' }} className="shp0" d="M2 5.8L10.08 5.8L7.02 9L9.18 9L13 5L9.18 1L7.02 1L10.08 4.2L2 4.2L2 5.8Z" />
      </g>
    </svg>
  )
}