interface ILanguage {
    [lang: string]: string
}

interface IProducts {
    TitleRest: ILanguage,
    TitleEcomm: ILanguage,
    PRest: ILanguage,
    PEcomm: ILanguage,
    ButtonText: ILanguage
}

export const Products: IProducts = {
    TitleRest: {
        ua: 'Мобільний застосунок для ресторанів, кафе, кав’ярень та інших сфер бізнесу',
        ru: 'Мобильное приложение для ресторанoв, кафе, кофеен и других сфер бизнеса',
        en: 'Mobile application for restaurants, cafes, coffee shops and other areas of business'
    },
    TitleEcomm: {
        ua: 'Мобільний застосунок для розвитку вашого бізнесу',
        ru: 'Мобильное приложение для развития вашего бизнеса',
        en: 'Mobile application for  developing your business'
    },
    PRest: {
        ua: 'Переведіть ваш бізнес в онлайн і організуйте роботу закладу за допомогою мобільного застосунку RestUp. Позбавтеся залежності від будь-яких обмежень, навіть, карантинних. Адже в RestUp є все, що потрібно для повноцінної, зручної та ефективної роботи з продажу та доставки замовлень вашим клієнтам.',
        ru: 'Переведите ваш бизнес в онлайн и организуйте работу заведения с помощью мобильного приложения RestUp. Избавьтесь от любых ограничений, даже карантинных. Ведь у RestUp есть все, что нужно для полноценной, удобной и эффективной работы по продаже и доставке заказов вашим клиентам.',
        en: 'Transfer your business online and organize your work with the help of the RestUp mobile application. Free yourself from any restrictions, even quarantine. After all, RestUp has everything you need for a full, convenient and effective work on the sale and delivery of orders to your customers. '
    },
    PEcomm: {
        ua: 'Велика частина ваших потенційних клієнтів – активні мобільні користувачі. Надайте їм можливість легко і швидко купувати ваш товар за допомогою зручного мобільного застосунку для інтернет-магазину E-Commerce App.',
        ru: 'Большинство ваших потенциальных клиентов – активные мобильные пользователи. Предоставьте им возможность легко и быстро покупать товар через удобное мобильного приложение для интернет-магазинов E-Commerce App.',
        en: 'Most of your potential customers are active mobile users. Give them the opportunity to easily and quickly buy your product with the help of a convenient mobile application for an online store E-Commerce App. '
    },
    ButtonText: {
        ua: 'Дізнатись детальніше',
        ru: 'Детальнее',
        en: 'More details '
    }
}