interface ILanguage {
  [lang: string]: string
}

interface IAbout {
  Title: ILanguage,
  P1: ILanguage,
  P2: ILanguage,
  P3: ILanguage
}

export const About: IAbout = {
  Title: {
    ua: 'Хто ми',
    ru: 'Кто мы',
    en: 'Who are we'
  },
  P1: {
    ua: 'КІТ Глобал – Агентство Комплексних Інформаційних Технологій. Ми надаємо послуги з розробки web- та mobile-продуктів. Створюємо проекти для бізнесу, громадських організацій, освітніх та державних установ в Україні, Європі, США. Агентство націлене на ефективне співробітництво з кожним клієнтом. Тому ми вибудовуємо довірливі та довготривалі партнерські відносини, що приносять взаємну вигоду для обох сторін діалогу.',
    ru: 'КИТ Глобал - Агентство Комплексных Информационных Технологий. Мы предоставляем услуги по разработке web- и mobile-продуктов. Создаем проекты для бизнеса, общественных организаций, образовательных и государственных учреждений в Украине, Европе, США. Агентство нацелено на эффективное сотрудничество с каждым клиентом. Поэтому мы выстраиваем доверительные и долгосрочные партнерские отношения, которые приносят взаимную выгоду для обеих сторон диалога.',
    en: 'KIT Global - Comprehensive Information Technology Agency. We provide web and mobile product development services. We create projects for business, non-governmental organizations, educational and government institutions in Ukraine, Europe, USA. The agency aims at effective cooperation with each client. Therefore, we build trusting and lasting partnerships that bring mutual benefit to both sides of the dialogue.'
  },
  P2: {
    ua: 'Спеціалізація студії в наданні комплексних послуг під ключ з розробки мобільних додатків та веб-сайтів. Наша команда виконує всі етапи створення продукту - від аудиту до супроводження та просування готового проекту. Це цінують наші клієнти, тому що їм не потрібно шукати декількох виконавців, скорочуються терміни розробки, формується об’єктивна вартість послуг.',
    ru: 'Специализация студии в предоставлении комплексных услуг под ключ по разработке мобильных приложений и веб-сайтов. Наша команда выполняет все этапы создания продукта - от аудита до сопровождения и продвижения готового проекта. Это ценят наши клиенты, потому что им не нужно искать нескольких исполнителей, сокращаются сроки разработки, формируется объективная стоимость услуг.',
    en: 'Studio specialization in the provision of complete turnkey services for the development of mobile applications and websites. Our team performs all stages of product creation - from audit to support and promotion of the finished project. This is appreciated by our customers because they do not need to look for multiple contractors, shorten the development time, generate the objective cost of services.'
  },
  P3: {
    ua: 'В умовах високої конкуренції бізнес не може розвиватися без впровадження сучасних ІТ-технологій. Наше агентство допомагає ефективно інтегрувати бізнес-рішення у мобільні додатки та веб-проекти. Прагнете досягти більшого? Готові реалізувати ваші прагнення і  допомогти в досягненні цілей!',
    ru: 'В условиях высокой конкуренции бизнес не может развиваться без внедрения современных ИТ-технологий. Наше агентство помогает эффективно интегрировать бизнес-решения в мобильные приложения и веб-проекты. Хотите достичь большего? Готовы реализовать ваши стремления и помочь в достижении целей!',
    en: 'In a highly competitive environment, business cannot grow without the introduction of modern IT technologies. Our agency helps you effectively integrate business solutions into mobile applications and web projects. Do you want to achieve more? Ready to fulfill your aspirations and help you achieve your goals!'
  }
}