import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import CheckboxTheme from './CheckboxTheme';
import LanguageContext from '../../contexts/Language';

import SwitcherLanguage from './SwitcherLanguage';
import SwitcherMenu from './SwitcherMenu';
import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';

interface LangProps {
  active?: boolean;
}

const SideController: React.FC = () => {
const { language, toggle } = useContext(LanguageContext);
  const [modal, setModal] = useState(false);
  const [menu, setMenu] = useState(false);
  const handleToggle = (type: 'en' | 'ua' | 'ru') => {
    if(window.innerWidth <= 425) {
      setModal(true);
    }
    toggle(type);
  }

  const handleClick = () => setMenu(true);

  return (
      <>
      <SideControllers>
        <CheckboxTheme />
        <LangWrapper>
          { 
            language === 'ua' 
              ? <Lang active onClick={() => handleToggle('ua')}>Ua</Lang> 
              : <Lang onClick={() => handleToggle('ua')}>Ua</Lang>
          }
          {/* <Lang onClick={() => toggle('ua')}>Ua</Lang> */}
          <Divider>|</Divider>
          { 
            language === 'en' 
              ? <Lang active onClick={() => handleToggle('en')}>En</Lang> 
              : <Lang onClick={() => handleToggle('en')}>En</Lang>
          }
          {/* <Lang onClick={() => toggle('en')}>En</Lang> */}
          <Divider>|</Divider>
          { 
            language === 'ru' 
              ? <Lang active onClick={() => handleToggle('ru')}>Ru</Lang> 
              : <Lang onClick={() => handleToggle('ru')}>Ru</Lang>
          }
          {/* <Lang active onClick={() => toggle('ru')}>Ru</Lang> */}
        </LangWrapper>
        <div onClick={() => handleClick()}>
          <Icon src={require('../../assets/images/icons/icon-menu-2x.png')} alt="Menu - icon" />
        </div>
      </SideControllers>
      { modal ? <SwitcherLanguage switch={() => setModal(false)} /> : null }
      { menu ? <SwitcherMenu switch={() => setMenu(false)} /> : null }
      </>
  );
}

export default SideController;

const mediaLang = `
  @media ${device.mobile} {
    display: none;
  }
`;

const Icon = styled.img`
  height: 37px;
  @media ${device.mobileL} {
    height: 32px;
  }
  @media ${device.mobile} {
    height: 28px;
  }
`;

const Lang = styled.div<LangProps>`
  font-size: 18px;
  cursor: pointer;
  font-weight: ${(props => props.active ? '700' : '500')};
  ${props => props.active ? 'color: #ff6e00;' : mediaLang}
`;

const LangWrapper = styled.div`
  ${flexCSS('row')}
`;


const Divider = styled.div`
  font-size: 15px;
  margin: 0px 3px;
  color: var(--theme-font-color);
  @media ${device.mobile} {
    display: none;
  }
`;


const SideControllers = styled.div`
  position: absolute;
  z-index: 10;
  right: 3%;
  height: 115px;
  width: fit-content;
  width: 290px;
  ${flexCSS('row', 'center', 'space-between')}
  @media ${device.mobile} {
    width: 150px;
  }
`;
