interface ILanguage {
  [lang: string]: string
}

interface IBeaspeak {
  Title: ILanguage,
  Form: {
    placeholderName: ILanguage,
    placeholderPhone: ILanguage,
    placeholderEmail: ILanguage,
    placeholderComment: ILanguage,
    buttonText: ILanguage
  },
  Notification: {
    attention: ILanguage,
    description: ILanguage,
    buttonText: ILanguage,
    url: ILanguage
  },
  ModalTitle: ILanguage,
  ModalCallTitle: ILanguage,
  ModalCallButton: ILanguage,
  SuccessModalTitle: ILanguage
}

export type EData = 'name' | 'email' | 'phone' | 'comment';

export const Bespeak: IBeaspeak = {
  Title: {
    ru: 'Заказать',
    ua: 'Замовити',
    en: 'To order'
  },
  Form: {
    placeholderName: {
      ru: 'Имя',
      en: 'Name',
      ua: "Ім'я"
    },
    placeholderPhone: {
      ru: 'Телефон',
      en: 'Phone',
      ua: 'Телефон'
    },
    placeholderEmail: {
      ru: 'E-mail',
      en: 'Mail',
      ua: 'E-mail'
    },
    placeholderComment: {
      ru: 'Комментарий',
      en: 'Comment',
      ua: 'Коментар'
    },
    buttonText: {
      ru: 'Отправить контактные данные',
      en: 'Send contact details',
      ua: 'Відправити контактні дані'
    }
  },
  Notification: {
    attention: {
      ru: 'Хорошие новости!',
      ua: 'Гарні новини!',
      en: 'Good news!'
    },
    description: {
      ru: 'Вы можете сразу заполнить бриф, мы сделаем оценку, и наш менеджер свяжется с Вами!',
      ua: "Ви можете одразу заповнити бриф, ми зробимо оцінку, і наш менеджер зв'яжеться з Вами!",
      en: 'You can immediately fill out the brief, we will make an assessment and our manager will contact you!'
    },
    buttonText: {
      ru: 'Заполнить бриф онлайн',
      en: 'Fill out a brief online',
      ua: 'Заповнити бриф онлайн'
    },
    url: {
      ru: 'https://kitapp.pro/brif-na-razrabotku-prilozheniya/',
      en: 'https://kitapp.pro/en/application-development-brief/',
      ua: 'https://kitapp.pro/uk/brif-na-rozrobku-dodatka/'
    }
  },
  ModalTitle: {
    ru: 'Заказать разработку',
    en: 'To order development',
    ua: 'Замовити розробку'
  },
  ModalCallTitle: {
    ru: 'Заказать звонок',
    en: 'Request a call',
    ua: 'Замовити дзвінок'
  },
  ModalCallButton: {
    ru: 'Отправить',
    en: 'Send',
    ua: 'Відправити'
  },
  SuccessModalTitle: {
    ru: 'Сообщение успешно отправлено!',
    en: 'Message sent successfully!',
    ua: 'Повідомлення успішно надіслано!'
  }
}