import React from 'react';
import styled from 'styled-components';
import LanguageContext from '../../contexts/Language';

import { flexCSS } from '../../assets/FitCSS';
import { Contact } from './JSON';
import { device } from '../../styled-components/MediaQueries';

const Contacts: React.FC = () => {
  const { language } = React.useContext(LanguageContext);
  return (
    <Wrapper>
       <IconWrapper>
          <Icon src={require('../../assets/images/icons/logo-k-1x.png')}/>
        </IconWrapper>
       <SubWrapper>
         <SubRow>{Contact.PrimaryPhone}</SubRow>
         <SubRow>{Contact.SecondaryPhone}</SubRow>
         <SubRow>{Contact.Mail}</SubRow>
         <SubRow>{Contact.Abbr[language]} {Contact.Code}</SubRow>
       </SubWrapper>
       <UnderSubRow>
        {Contact.LineUp[language]}
        <LinkWrapper href="https://clutch.co/profile/kit-global" target="_blank">
          <Icon src={require('../../assets/images/icons/clutch.svg')} />
        </LinkWrapper>
       </UnderSubRow>
    </Wrapper>
    );
  }
  
export default Contacts;

const Wrapper = styled.div`
  width: 100%;
  ${flexCSS('column', 'center', 'center')}
  font-size: 10pt;
  @media ${device.mobileC} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const SubRow = styled.div`
  color: var(--theme-footer-font-color);
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 9pt;
  }
  @media ${device.mobileC} {
    width: 50%;
    margin-bottom: 8px;
  }
`;

const UnderSubRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--theme-footer-font-color);
  font-weight: 600;
  
  @media ${device.tablet} {
    font-size: 9pt;
    flex-direction: column;
    gap:24px;
  }
`;

const SubWrapper = styled.div`
  width: 100%;
  ${flexCSS('row', 'center', 'space-between')}
  margin-bottom: 15px;
  @media ${device.mobileC} {
    flex-wrap: wrap;
    width: 90%;
  }
`;

const IconWrapper = styled.div`
  width: 20%;
  @media ${device.laptop} {
    width: 10%;
  }
  ${flexCSS('row', 'center', 'flex-start')}
  display: none;
  @media ${device.mobileC} {
    ${flexCSS('row', 'center', 'flex-start')}
    width: 10%;
    margin-bottom: 20px;
  }
`;

const Icon = styled.img`
  @media ${device.tablet} {
    height: 40px;
  }
`;

const LinkWrapper = styled.a`
  display:inline-flex;
  justify-content: flex-end;
  text-decoration: none;
  width: 100%;
  & img{
    max-width: 100%;
  }
  @media ${device.tablet} {
    justify-content: center;
  }
`