import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import BG from '../../assets/images/slider/web-slider-bg.png';
import { device } from '../../styled-components/MediaQueries';
import { ISlider } from './json.slider';

interface SliderProps {
  slides: ISlider[],
  index?: number
}

const WebSlider: React.FC<SliderProps> = ({
  slides
}) => {
  const handleClick = (link: string | undefined) => {
    window.open(link, '_blank');
  }
  return (
    <Wrapper>
      {
        slides.map((slide: ISlider) => 
          <Icon
            key={slide.id} 
            onClick={() => handleClick(slide.link ? slide.link : 'kit-global.agency')}
            src={require('../../assets/images/slider/web/'+slide.src)} 
            alt="slide item"
            style={{height: '100%', width: '100%', 'box-shadow':'-1px 0px 0px 0px black, 1px 0px 0px 0px black'}}
          ></Icon>
        )
      }
    </Wrapper>
  )
}

export default WebSlider;

const Wrapper = styled.div`
  margin-top: 30px;
  width: 512px;
  height: 256px;
  border: 6px solid #999999;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  background-color: var(--theme-bg-color);
  background-size: cover;
  background-image: url(${BG});
  @media ${device.laptopX} {
    width: 410px;
    height: 205px;
  }
  @media ${device.mobileL} {
    width: 360px;
    height: 180px;
  }
  @media ${device.mobile} {
    width: 330px;
    height: 165px;
  }
  @media ${device.mobileM} {
    width: 300px;
    height: 150px;
  }
  @media ${device.mobileS} {
    width: 270px;
    height: 135px;
  }
`;