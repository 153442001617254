import React from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import { flexCSS } from '../../assets/FitCSS';
import LanguageContext from '../../contexts/Language';
import ThemeContext from '../../contexts/Theme';
import { About } from './JSON';
import { device } from '../../styled-components/MediaQueries';

const AboutWrapper: React.FC<{
  style?: any,
}> = ({style, children}) => {
  const { language } = React.useContext(LanguageContext);
  const { dark } = React.useContext(ThemeContext)
  return (
    <ScrollableAnchor id={'whoarewe'}>
      <Wrapper>
        <SubWrapper>
          <LeftSide>
            <Title>{About.Title[language]}</Title>
            <Paragraph>{About.P1[language]}</Paragraph>
            <Paragraph>{About.P2[language]}</Paragraph>
            <Paragraph>{About.P3[language]}</Paragraph>
          </LeftSide>
          <RightSide>
            <Image src={require(`../../assets/images/icons/local-k-${dark ? 'dark' : 'light'}.png`)}/>
          </RightSide>
        </SubWrapper>
      </Wrapper>
    </ScrollableAnchor>
  )
}

export default AboutWrapper;

const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg2-color);
  ${flexCSS('row')}
  color: var(--theme-font-color);
`;

const SubWrapper = styled.div`
  width: 65%;
  ${flexCSS('row')}
  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.laptopM} {
    ${flexCSS('column')}
  }
  @media ${device.mobileC} {
    width: 95%;
  }
`;

const Title = styled.div`
  color: var(--theme-title-color);
  text-align: start;
  margin: 30px;
  font-size: 20pt;
  font-weight: 700;
  @media ${device.mobileC} {
    text-align: center;
    margin: 30px 0px;
    width: 100%;
  }
`;

const Paragraph = styled.div`
  text-align: left;
  font-size: 9pt;
  margin-bottom: 10px;
  margin-left: 30px;
  font-weight: 600;
  line-height: 17px;
  color: var(--theme-input-color);
  @media ${device.laptop} {
    font-size: 8pt;
  }
  @media ${device.mobileC} {
    margin-left: 0px;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 500px;
  ${flexCSS('column', 'flex-start')}
  @media ${device.laptop} {
    height: 450px;
  }
  @media ${device.laptopM} {
    width: 100%;
    height: fit-content;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 500px;
  @media ${device.laptop} { height: 450px; }
  @media ${device.laptopM} { width: 100%; }
  @media ${device.mobileL} { height: 400px; }
  @media ${device.mobile} { height: 375px; }
  @media ${device.mobileM} { height: 350px; }
  @media ${device.mobileXM} { height: 320px; }
  @media ${device.mobileS} { height: 310px; }
`;

const Image = styled.img`
  height: 100%;
`;
