import React from 'react';
import styled, { keyframes } from 'styled-components';

import ThemeContext from '../contexts/Theme';
import { flexCSS } from '../assets/FitCSS';
import Icon from './Icon'
import { device } from '../styled-components/MediaQueries';

const SideLinks: React.FC = () => {
  const { dark } = React.useContext(ThemeContext);
  const handleLink = (url: string) => window.open(url, '_blank');
  return (
    <Wrapper>
      <Links>
        <Icon onClick={() => handleLink('https://www.facebook.com/KITGlobalAgency')} src={dark ? require('../assets/images/icons/fb-dark.png') : require('../assets/images/icons/fb-light.png')} alt="logo facebook" />
        <Icon onClick={() => handleLink('https://www.instagram.com/kitapp_global/')} src={dark ? require('../assets/images/icons/ig-dark.png') : require('../assets/images/icons/ig-light.png')} alt="logo instagram" />
        <Icon onClick={() => handleLink('https://www.behance.net/kitapp_pro')} src={dark ? require('../assets/images/icons/be-dark.png') : require('../assets/images/icons/be-light.png')} alt="logo benahce" />
      </Links>
    </Wrapper>
  );
}

export default SideLinks;

const Animation = keyframes`
  0% {
    right: -100%;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
`;

const Links = styled.div`
  padding: 16px 0px;
  width: 45px;
  height: 100px;
  background: var(--c-orange);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  ${flexCSS('column', 'center', 'space-between')}
  img {
    height: 23px;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  height: 100%;
  ${flexCSS('column', 'center', 'center')}
  animation: ${Animation} 2s ease-in-out 0s 1;
  @media ${device.mobileC} {
    display: none;
  }
`;
