export const JSON_advantages: {
  index: string,
  title: {[name: string]: string}
}[] = [
  {
    index: '1',
    title: {
      'ru': 'Работа по договору',
      'en': 'Work under the contract',
      'ua': 'Робота за договором'
    }
  },
  {
    index: '2',
    title: {
      'ru': 'Фиксированная цена услуг',
      'en': 'Fixed price of services',
      'ua': 'Фіксована ціна послуг'
    }
  },
  {
    index: '3',
    title: {
      'ru': 'Поэтапное закрытие проектов',
      'en': 'Phased closure of projects',
      'ua': 'Поетапне закриття проектів'
    }
  },
  {
    index: '4',
    title: {
      'ru': 'Web и Mobile интеграция',
      'en': 'Web and Mobile Integration',
      'ua': 'Web та Mobile інтеграція'
    }
  },
  {
    index: '5',
    title: {
      'ru': 'Креативный подход к решению любых задач',
      'en': 'Creative approach for solving any problems',
      'ua': 'Креативний підхід до вирішення будь-яких завдань'
    }
  },
  {
    index: '6',
    title: {
      'ru': 'Многолетний опыт и сотни довольных заказчиков',
      'en': 'Many years of experience and hundreds of satisfied customers',
      'ua': 'Багаторічний досвід і сотні задоволених клієнтів'
    }
  }
];