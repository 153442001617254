import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import LanguageContext, { TranslatorObject } from '../../contexts/Language';
import ScrollableAnchor from 'react-scrollable-anchor';

import { flexCSS } from '../../assets/FitCSS';
import Advantage from './Advantage';
import Form from './Form';
import { JSON_advantages } from './json.advantages';
import Steps from './Steps';
import { device } from '../../styled-components/MediaQueries';
import VisibilitySensor from 'react-visibility-sensor';

const StepWrapper: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const [visible, setVisible] = useState<boolean>(false);
 
  const change = (isVisible: boolean): void => {
    if(isVisible && !visible){
      setVisible(true);
    }
  }

  return (
    <Wrapper>
      <ScrollableAnchor id={'stages'}>
        <SubWrapper isMargin={false}>
          <Title>{TranslatorObject.Steps.title_steps[language]}</Title>
          <VisibilitySensor onChange={change}>
            <Steps visible={visible}/>
          </VisibilitySensor>
        </SubWrapper>
      </ScrollableAnchor>
      <ScrollableAnchor id={'rules'}>
        <SubWrapper isMargin={true}>
          <Title>{TranslatorObject.Steps.title_advantages[language]}</Title>
          <WrapperForAdvantages>
            {
              JSON_advantages.map(advantage => {
                return <Advantage key={advantage.index} index={advantage.index} title={advantage.title[language]} img={advantage.index}/>
              })
            }
          </WrapperForAdvantages>
        </SubWrapper>
      </ScrollableAnchor>
      <SubWrapper isMargin={false}>
       <Form />
      </SubWrapper>
    </Wrapper>
    );
  }
  
export default StepWrapper;

const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg2-color);
  color: var(--theme-font-color);
  padding-bottom: 50px;
  ${flexCSS('column')}
`;

const SubWrapper = styled.div<{isMargin: boolean}>`
  width: 65%;
  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.mobileC} {
    width: 95%;
  }
  ${flexCSS('column', 'flex-start', 'space-between')}
  ${props => props.isMargin ? 'margin-top: 70px;' : null }
`;

const Title = styled.div`
  padding: 30px 0px;
  margin-left: 30px;
  font-size: 20pt;
  font-weight: 700;
  text-align: start;
  width: 100%;
  color: var(--theme-title-color);
  @media ${device.laptop} {
    margin-left: 0;
  }
  @media ${device.mobileC} {
    text-align: center;
  }
`;

const WrapperForAdvantages = styled.div`
  ${flexCSS('row')}
  flex-wrap: wrap;
`;