import React, { useState, useEffect } from 'react';

const AnchorContext = React.createContext({
  anchore: 'services',
  toggleAnchor: (anchore: 'greeting' | 'services' | 'portfolio' | 'products' | 'stages' | 'rules' | 'crew' | 'whoarewe' | 'toorder') => { },
});

export default AnchorContext;

export const AnchorProvider = (props: any) => {
  const [anchore, setAnchor]: any = useState('greeting');

  useEffect(() => {
    scrollStop(() => setAnchor(window.location.hash.split('#')[1]));
  }, []);

  const scrollStop = (callback: Function): void => {
    if (!callback || typeof callback !== 'function') return;
    let isScrolling: any;
    window.addEventListener('scroll', () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => callback(), 0);
    }, false);
  };

  const toggleAnchor = (lang: 'greeting' | 'services' | 'portfolio' | 'products' | 'stages' | 'rules' | 'crew' | 'whoarewe' | 'toorder') => {
    setAnchor(lang);
  };

  return <AnchorContext.Provider value={{ anchore, toggleAnchor }}>
    {props.children}
  </AnchorContext.Provider>
}