import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './contexts/Theme';
import { LanguageProvider }  from './contexts/Language';
import { AnchorProvider } from './contexts/Anchor';

ReactDOM.render(//6LcnLc4UAAAAAFyp5lLI1PxdTqU4cpNV--OsOc2E
  <ThemeProvider>
    <LanguageProvider>
      <AnchorProvider>
        <App />
      </AnchorProvider>
    </LanguageProvider>
  </ThemeProvider>, document.getElementById('root'));
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
