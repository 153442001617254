import React, { useContext }  from 'react';
import styled from 'styled-components';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Icon from '../Icon';
import BG from '../../assets/images/slider/slider-bg.jpg';
import { LinkText } from './json.slider'
import LanguageContext from '../../contexts/Language';

interface SliderProps {
  slides: any[],
  index?: number
}

const MobSlider: React.FC<SliderProps> = ({
  slides, children, index
}) => {
  const { language } = useContext(LanguageContext);
  const handleLink = (link: string) => {
    window.open(link, '_blank');
  }
  return (
    <>
      <Wrapper>
        <CarouselProvider
          naturalSlideWidth={185}
          naturalSlideHeight={330}
          totalSlides={slides.length}
        >
          <Slider>
            {
              slides.map(slide => 
                <Slide 
                  key={slide.id}
                  index={slide.id}
                >
                  <Icon 
                    src={require('../../assets/images/slider/mobile/'+slide.src)} 
                    alt="slide item"
                    style={{height: '100%', width: '100%', 'box-shadow':'-1px 0px 0px 0px black, 1px 0px 0px 0px black'}}
                  ></Icon>
                </Slide>
              )
            }
          </Slider>
        </CarouselProvider>
      </Wrapper>
      <Link onClick={() => handleLink(slides[0].link)}>{LinkText.mobile[language]}</Link>
    </>
  )
}

export default MobSlider;

const Wrapper = styled.div`
  position: relative;
  transform: rotate(0.0001deg);
  width: 185px;
  height: 330px;
  /*border: 10px solid #999999;*/
  border-bottom: 20px solid var(--theme-slider-border);
  border-top: 20px solid var(--theme-slider-border);
  border-left: 1px solid var(--theme-slider-border);
  border-right: 1px solid var(--theme-slider-border);
  border-radius: 30px;
  overflow: hidden;
  background-color: var(--theme-bg-color);
  background-size: cover;
  background-image: url(${BG});
`;

const Link = styled.div`
  margin-top: 20px;
  font-size: 9pt;
  cursor: pointer;
  font-weight: 700;
  transition: 0.5s;
  &:hover {
    color: var(--c-orange);
  }
`;