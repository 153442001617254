import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import LanguageContext from '../../contexts/Language';
import { flexCSS } from '../../assets/FitCSS';

import { Bespeak, EData } from '../Bespeak/JSON';
import { Button, Input, Arrow } from '../StepsWorking/Form';
import { device } from '../../styled-components/MediaQueries';
import axios from 'axios';
import { API_dev_order } from '../../PHP/API';
import { Messages } from '../../PHP/Messages';
import SuccessModal from './SuccessModal';

interface IModal {
  switch: Function,
}

const SwitcherMenu: React.FC<IModal> = (props) => {
  const { language } = useContext(LanguageContext);
  const [side, sideOut] = useState<'in' | 'out'>('in');

  const handleClick = (): void => {
    sideOut('out');
    setTimeout(() => props.switch(), 700);
  }

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [validation, setValid] = useState<{
    name: boolean, phone: boolean, mail: boolean
  }>({ name: true, phone: true, mail: true });
  
  const [menu, setMenu] = useState(false);

  const handleSubmit = (e: any, callback: Function) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_dev_order}`,
      headers: { 
        'content-type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type'
      },
      data: {
        language,
        name,
        email,
        phone,
        type: 'Web и Mobile',
        comment,
        message: Messages.toOrderDev
      }
    }).then(result => {
      // console.log('success', result);
      callback();
      setMenu(true);
    }).catch(error => {
      console.log('success', error);
    });
    
  }

  useEffect(() => {
    menu && setTimeout(() => handleClick(), 2000);
  }, [menu]);

  const sendData = (e: any) => {
    if(!name.length) {
      setValid({...validation, name: false})
      return;
    }
    if(!phone.length) {
      setValid({...validation, phone: false})
      return;
    }
    if(!email.length) {
      setValid({...validation, mail: false})
      return;
    }
    handleSubmit(e, () => {
      setName('');
      setEmail('');
      setPhone('');
      setComment('');
    });
  }

  const set = (type: EData, event: any): void => {
    switch (type) {
      case 'comment': setComment(event.target.value); break;
      case 'name': 
        if(event.target.value.length) setValid({...validation, name: true})
        setName(event.target.value); 
        break;

      case 'email': 
        if(event.target.value.length) setValid({...validation, mail: true})
        setEmail(event.target.value); 
        break;

      case 'phone': 
        if(event.target.value.length) setValid({...validation, phone: true})
        setPhone(event.target.value); 
        break;

      default: break;
    }
  }

  return (
    <Switcher type={side}>
      <Wrapper type={side}>
        <Head>
          <Title>{Bespeak.ModalTitle[language]}</Title>
          <Cancel onClick={() => handleClick()}>
            <X/>
          </Cancel>
        </Head>
        <SubWrapper>
          <CInput valid={!validation.name} placeholder={Bespeak.Form.placeholderName[language]} value={name} onChange={(event: any) => set('name', event)} />
          <CInput valid={!validation.phone} placeholder={Bespeak.Form.placeholderPhone[language]} value={phone} onChange={(event: any) => set('phone', event)} />
          <CInput valid={!validation.mail} placeholder={Bespeak.Form.placeholderEmail[language]} value={email} onChange={(event: any) => set('email', event)} />
          <Textarea placeholder={Bespeak.Form.placeholderComment[language]} value={comment} onChange={(event: any) => set('comment', event)} />
          <CButton onClick={e => sendData(e)}>{Bespeak.Form.buttonText[language]}<Arrow /></CButton>
        </SubWrapper>
      </Wrapper>
      { menu ? <SuccessModal switch={() => setMenu(false)} /> : null }
    </Switcher>
  ); 
}

export default SwitcherMenu;

export const X: React.FC<{ style?: any }> = ({ style, children}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="13" height="13">
      <defs>
        <image width="13" height="13" id="img1" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAk0lEQVQoU53S2Q3CMBBF0fvqCwIKgbSA04JJIyBIe4McjIUXjMBf/pijWQVgRwaduYd/79nITp6LAgBuwKYHU5wY9MrUgxnwLCvqwRKE2IRasAUqlEExYZwIPXiW9wFlmVKpI24FhtPMVE60QqkkwyFca6rtnmJJn9ZRT6/ooQV/21M8gH8uYv/MdGCrmevX24txD+8gW4ENLkjoAAAAAElFTkSuQmCC"/>
      </defs>
      <g id="форма копия">
        <g id="Группа 2">
          <use id="Фигура 3" href="#img1" transform="matrix(2,0,0,2,0,0)"/>
        </g>
      </g>
    </svg>
  )
}

const InvalidAnimation = keyframes`
  0% { box-shadow: inset 0 0 10px red; }
  5% { box-shadow: inset 0 0 9px red; }
  10% { box-shadow: inset 0 0 8px red; }
  15% { box-shadow: inset 0 0 7px red; }
  20% { box-shadow: inset 0 0 6px red; }
  25% { box-shadow: inset 0 0 5px red; }
  30% { box-shadow: inset 0 0 6px red; }
  35% { box-shadow: inset 0 0 7px red;}
  40% { box-shadow: inset 0 0 8px red; }
  45% { box-shadow: inset 0 0 9px red; }
  50% { box-shadow: inset 0 0 10px red; }
  55% { box-shadow: inset 0 0 10px red; }
  60% { box-shadow: inset 0 0 9px red; }
  65% { box-shadow: inset 0 0 8px red; }
  70% { box-shadow: inset 0 0 7px red; }
  75% { box-shadow: inset 0 0 6px red; }
  80% { box-shadow: inset 0 0 5px red; }
  85% { box-shadow: inset 0 0 6px red; }
  90% { box-shadow: inset 0 0 7px red; }
  95% { box-shadow: inset 0 0 8px red; }
  100% { box-shadow: inset 0 0 9px red; }
`;

const DefaultEmptyAnimation = keyframes``;

const Head = styled.div`
  ${flexCSS('row', 'center', 'space-between')}
  width: 100%;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 13pt;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-left: 10%;
`;

const Cancel = styled.div`
  color: var(--c-orange);
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    transform: rotate(180deg);
  }
`;

const AnimationSwitchSideIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const AnimationSwitchSideOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnimationSwitchMenuSideIn = keyframes`
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
`;

const AnimationSwitchMenuSideOut = keyframes`
  0% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 100%;
    opacity: 0;
  }
`;

const Switcher = styled.div<{type: 'in' | 'out'}>`
  ${flexCSS('row')}
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  background-color: #00000090;
  ${props => props.type === 'in' ? 'opacity: 1;' : 'opacity: 0;'}
  animation: ${props => props.type === 'in' ? AnimationSwitchSideIn : AnimationSwitchSideOut} 0.5s ease-in-out 0s 1;
`;

const MarginBottom = (margin: number = 10, v: '%' | 'px' | 'vh' = 'px') => ({'margin-bottom': `${margin}${v}`});

const Wrapper = styled.div<{type: 'in' | 'out'}>`
  transition: 0.7s;
  width: 600px;
  background: var(--theme-bg-form-color);
  ${flexCSS('column')}
  color: var(--theme-font-color);
  box-shadow: var(--theme-form-shadow);
  border: 1px solid var(--c-orange);
  border-radius: 5px;
  padding: 40px;
  animation: ${props => props.type === 'in' ? AnimationSwitchMenuSideIn : AnimationSwitchMenuSideOut} 0.5s ease-in-out 0s 1;
  @media ${device.tablet} {
    width: 500px;
  }
  @media ${device.mobileC} {
    width: 400px;
    padding: 30px;
  }
  @media ${device.mobileL} {
    width: 300px;
    padding: 20px;
  }
`;

const SubWrapper = styled.div`
  width: 80%;
  ${flexCSS('column', 'flex-start')}
  @media ${device.mobileL} {
    width: 95%;
  }
`;

const CInput = styled(Input)`
  width: 97%;
  background-color: var(--theme-bg2-color);
  ${MarginBottom()}
  box-shadow: var(--theme-inputs-shadow);
  padding: 12px 10px;
  @media ${device.laptop} {
    width: 97%;
  }
  @media ${device.mobileC} {
    width: 97%;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
  animation: ${props => props.valid ? InvalidAnimation : DefaultEmptyAnimation} 1.5s ease-in-out 0s infinite;
`;

const CButton = styled(Button)`
  margin: 0px;
  width: fit-content;
  margin-top: 10px;
  @media ${device.laptop} {
    width: 101%;
  }
`;

const Textarea = styled.textarea`
  width: 97%;
  resize: vertical;
  background-color: var(--theme-bg2-color);
  border: none;
  outline: none;
  box-shadow: var(--theme-inputs-shadow);
  &:focus {
    outline: none;
  }
  color: var(--theme-input-color);
  padding: 12px 10px;
  border-radius: 3px;
  height: 60px;
  ${MarginBottom()}
  min-height: 80px;
  max-height: 80px;
`;
