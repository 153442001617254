import React from 'react';
import styled from 'styled-components';

const Text: React.FC<{
  style?: any
}> = ({
  children, style
}) => {
  const Text = styled.div`${style}`;
  return (
    <Text>{children}</Text>
  )
}

export default Text;