import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import { flexCSS } from '../../assets/FitCSS';
import LanguageContext from '../../contexts/Language';
import { Bespeak } from './JSON';
import { Button } from '../Portfolio/PortfolioWrapper';

import OrderForm from './OrderForm';
import NotificationForm from './NotificationForm';
import { device } from '../../styled-components/MediaQueries';

const BespeakWrapper: React.FC<{
  style?: any,
}> = ({ style, children }) => {
  const { language } = useContext(LanguageContext);
  const [activeButton, setActiveButton] = useState<'mobile' | 'web'>('mobile');
  return (
    <ScrollableAnchor id={'toorder'}>
      <Wrapper>
        <SubWrapper>
          <LeftSide>
            <Title>{Bespeak.Title[language]}</Title>
            <CButtons>
              {/* <Button onClick={() => setActiveButton('web')} active={activeButton === 'web'}>Web</Button> */}
              <Button onClick={() => setActiveButton('mobile')} active={activeButton === 'mobile'}>Mobile</Button>
            </CButtons>
            <OrderForm style={{ 'marginLeft': '30px' }} target={activeButton} />
          </LeftSide>
          <RightSide>
            <NotificationForm />
          </RightSide>
        </SubWrapper>
      </Wrapper>
    </ScrollableAnchor>
  )
}

export default BespeakWrapper;

const CButtons = styled.div<{ style?: any }>`
  ${flexCSS('row', 'center', 'flex-start')}
  width: 100%;
  margin-bottom: 40px;
  margin-left: 30px;
  ${(props: { style?: any }) => props.style ? props.style : null}
  @media ${device.mobileC} { justify-content: center; }
  @media ${device.laptop} {
    margin-left: 0;
  }
`;


const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg-color);
  ${flexCSS('row')}
  color: var(--theme-font-color);
`;

const SubWrapper = styled.div`
  width: 65%;
  @media ${device.laptop} {
    width: 85%;
  }
  ${flexCSS('row')}
  @media ${device.tablet} {
    ${flexCSS('column')}
  }
  @media ${device.mobileC} {
    width: 95%;
  }
`;

const Title = styled.div`
  color: var(--theme-title-color);
  text-align: start;
  margin: 30px;
  font-size: 20pt;
  font-weight: 700;
  @media ${device.laptop} {
    margin: 30px 0;
  }
  @media ${device.mobileC} {
    margin: 30px 0;
    text-align: center;
  }
`;

const LeftSide = styled.div`
  width: 70%;
  height: fit-content;
  margin: 0px 0px 30px 30px;
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobileC} {
    margin: 0 auto;
  }
`;

const RightSide = styled.div`
  width: 30%;
  ${flexCSS('column', 'flex-start')}
  @media ${device.tablet} {
    width: 70%;
    margin-bottom: 30px;
  }
`;