import React, { useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

import LanguageContext, { TranslatorObject } from '../../contexts/Language';
import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';
import { API_dev_info } from '../../PHP/API';
import { Messages } from '../../PHP/Messages';
import SuccessModal from '../Greeting/SuccessModal';

const Form: React.FC<{ style?: any }> = ({ style, children}) => {
  const { language } = useContext(LanguageContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [validation, setValid] = useState<{
    name: boolean, mail: boolean
  }>({ name: true, mail: true });

  const [menu, setMenu] = useState(false);

  const handleSubmit = (e: any, callback: Function) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_dev_info}`,
      headers: { 
        'content-type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type'
      },
      
      data: {
        language,
        name,
        email,
        type: 'Web и Mobile',
        message: Messages.getInformation
      }
    }).then(result => {
      // console.log('success', result);
      callback();
      setMenu(true);
    }).catch(error => {
      console.log('success', error);
    });
  }

  const sendData = (e: any) => {
    if(!name.length) {
      setValid({...validation, name: false})
      return;
    }
    if(!email.length) {
      setValid({...validation, mail: false})
      return;
    }
    handleSubmit(e, () => {
      setName('');
      setEmail('');
    })
  }

  const set = (type: 'name' | 'email', event: any) => {
    switch (type) {
      case 'name':
        if(event.target.value.length) setValid({...validation, name: true})
        setName(event.target.value)
        break;

      case 'email':
        if(event.target.value.length) setValid({...validation, mail: true})
        setEmail(event.target.value)
        break;

      default: break;
    }
  }

  return (
    <Wrapper>
      <Title>{TranslatorObject.Form.title[language]}</Title>
      <SubWrapper>
        <Input valid={!validation.name} placeholder={TranslatorObject.Form.placeholderName[language]} value={name} onChange={(event: any) => set('name', event)}/>
        <Input valid={!validation.mail} placeholder={TranslatorObject.Form.placeholderEmail[language]} value={email} onChange={(event: any) => set('email', event)}/>
        <Button onClick={e => sendData(e)}>{TranslatorObject.Form.buttonText[language]}<Arrow /></Button>
      </SubWrapper>
      { menu ? <SuccessModal switch={() => setMenu(false)} /> : null }
    </Wrapper>
    );
  }
  
export default Form;

const InvalidAnimation = keyframes`
  0% { box-shadow: inset 0 0 10px red; }
  5% { box-shadow: inset 0 0 9px red; }
  10% { box-shadow: inset 0 0 8px red; }
  15% { box-shadow: inset 0 0 7px red; }
  20% { box-shadow: inset 0 0 6px red; }
  25% { box-shadow: inset 0 0 5px red; }
  30% { box-shadow: inset 0 0 6px red; }
  35% { box-shadow: inset 0 0 7px red;}
  40% { box-shadow: inset 0 0 8px red; }
  45% { box-shadow: inset 0 0 9px red; }
  50% { box-shadow: inset 0 0 10px red; }
  55% { box-shadow: inset 0 0 10px red; }
  60% { box-shadow: inset 0 0 9px red; }
  65% { box-shadow: inset 0 0 8px red; }
  70% { box-shadow: inset 0 0 7px red; }
  75% { box-shadow: inset 0 0 6px red; }
  80% { box-shadow: inset 0 0 5px red; }
  85% { box-shadow: inset 0 0 6px red; }
  90% { box-shadow: inset 0 0 7px red; }
  95% { box-shadow: inset 0 0 8px red; }
  100% { box-shadow: inset 0 0 9px red; }
`;

const DefaultEmptyAnimation = keyframes``;

const Wrapper = styled.div`
  margin-top: 50px;
  background: var(--theme-bg-form-color);
  width: 100%;
  box-shadow: var(--theme-form-shadow);
  border: 1px solid var(--c-orange);
`;

const Title = styled.div`
  width: 100%;
  text-align: left;
  color: var(--theme-color);
  font-weight: 600;
  font-size: 14pt;
  padding: 40px 30px 20px 30px;
  @media ${device.laptopM} {
    width: 90%;
    padding-right: 0px;
  }
  @media ${device.mobileC} {
    text-align: center;
    padding: 30px 10px 20px 10px;
  }
`;

const SubWrapper = styled.div`
  ${flexCSS('row', 'center', 'flex-start')}
  padding: 0px 30px 30px;
  @media ${device.mobileC} {
    ${flexCSS('column')}
  }
`;

export const Input = styled.input<{valid?: boolean}>`
  width: 33%;
  &:focus {
    outline: none;
  }
  color: var(--theme-input-color);
  background-color: var(--theme-input-bg);
  box-shadow: var(--theme-input-shadow);
  border: none;
  padding: 10px;
  border-radius: 3px;
  margin-right: 10px;
  @media ${device.laptop} {
    width: 25%;
  }
  @media ${device.mobileC} {
    width: 100%;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
  animation: ${props => props.valid ? InvalidAnimation : DefaultEmptyAnimation} 1.5s ease-in-out 0s infinite;
`;

export const Button = styled.button`
  width: 25%;
  @media ${device.laptop} {
    width: 40%;
  }
  @media ${device.laptopM} {
    width: 93%;
  }
  @media ${device.mobileC} {
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
  }
  color: var(--theme-form-button-color);
  border: 1px solid var(--c-orange);
  padding: 10px;
  margin-left: 10px;
  ${flexCSS('row')}
  box-shadow: var(--theme-form-button-shadow);
  border-radius: 5px;
  background: var(--theme-form-button-bg);
  outline: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    --theme-form-button-color: white;
    background: var(--c-orange)
  }
`;

export const Arrow: React.FC<{ style?: any }> = ({ style, children}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 10" width="12" height="9" style={{padding: '0px 5px', ...style}}>
      <g id="Группа 1">
        <path id="Фигура 1 копия 5" style={{fill: 'var(--theme-form-button-color)'}}className="shp0" d="M2 5.8L10.08 5.8L7.02 9L9.18 9L13 5L9.18 1L7.02 1L10.08 4.2L2 4.2L2 5.8Z" />
      </g>
    </svg>
  )
}