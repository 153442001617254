import React from 'react';
import styled from 'styled-components';
import { flexCSS } from '../../assets/FitCSS';
import LanguageContext from '../../contexts/Language';
import { Products } from './JSON';
import { device } from '../../styled-components/MediaQueries';
import { ImageWrapper, Image, LeftSide, RightSide, Title, Title2, Paragraph, ButtonLink } from './RestupWrapper';
import { Arrow } from '../StepsWorking/Form';
import { onClickUrl } from './helpers';


const EcommWrapper: React.FC<{
    style?: any,
}> = ({ style, children }) => {
    const { language } = React.useContext(LanguageContext);
    return (
        <Wrapper>
            <SubWrapperEcomm>
                <RightSide>
                    <ImageWrapper><Image src={require(`../../assets/images/products/ecom1.png`)} /></ImageWrapper>
                    <ImageWrapper><Image src={require(`../../assets/images/products/ecom2.png`)} /></ImageWrapper>
                </RightSide>
                <LeftSide>
                    <LogoEcomm> <Image src={require(`../../assets/images/products/logo2.png`)} /></LogoEcomm>
                    <Title>E-commerce App</Title>
                    <Title2>{Products.TitleEcomm[language]}</Title2>
                    <Paragraph>{Products.PEcomm[language]}</Paragraph>
                    <ButtonLink onClick={onClickUrl('https://ecom.kitapp.pro/')}>{Products.ButtonText[language]}<Arrow /></ButtonLink>
                </LeftSide>
            </SubWrapperEcomm>
        </Wrapper>
    )
}

export default EcommWrapper;


const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  ${flexCSS('row')}
`;
const SubWrapperEcomm = styled.div`
  width: 60%;
  padding:30px 0 50px 0;
  ${flexCSS('row')}
  justify-content:space-between;
  gap: 30px;
  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.laptopM} {
    flex-direction:column-reverse;
  }
  @media ${device.mobileC} {
    width: 95%;
    padding-bottom:50px;
  }
`;
const LogoEcomm = styled.div`
  width:48px;
  height:40px;
  margin-bottom:20px;
  @media ${device.mobileC} {
      margin: 30px auto 10px auto;
  }
`;







