interface ILanguage {
  [language: string]: string
}

interface IHeader {
  services: ILanguage,
  portfolio: ILanguage,
  products: ILanguage,
  steps: ILanguage,
  rules: ILanguage,
  crew: ILanguage,
  whoarewe: ILanguage,
  toorder: ILanguage
}

export const HeadData: IHeader = {
  services: {
    ru: 'Услуги',
    en: 'Services',
    ua: 'Послуги'
  },
  portfolio: {
    ru: 'Портфолио',
    en: 'Portfolio',
    ua: 'Портфоліо',
  },
  products: {
    ru: 'Продукты',
    en: 'Products',
    ua: 'Продукти',
  },
  steps: {
    ru: 'Этапы',
    en: 'Stages',
    ua: 'Етапи'
  },
  rules: {
    ru: 'Условия',
    en: 'Conditions ',
    ua: 'Умови '
  },
  crew: {
    ru: 'Команда',
    en: 'Crew',
    ua: 'Команда'
  },
  whoarewe: {
    ru: 'Кто мы',
    en: 'About us',
    ua: 'Хто ми'
  },
  toorder: {
    ru: 'Заказать',
    en: 'To order',
    ua: 'Замовити'
  }
}