import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import LanguageContext from '../../contexts/Language';
import { flexCSS } from '../../assets/FitCSS';

import { Bespeak } from '../Bespeak/JSON';
import { device } from '../../styled-components/MediaQueries';

interface IModal {
  switch: Function
}

const SuccessModal: React.FC<IModal> = (props) => {
  const { language } = useContext(LanguageContext);
  const [side, sideOut] = useState<'in' | 'out'>('in');

  const handleClick = (): void => {
    sideOut('out');
    setTimeout(() => props.switch(), 700);
  }

  useEffect(() => {
    setTimeout(() => handleClick(), 2000);
  });

  return (
    <Switcher type={side}>
      <Wrapper type={side}>
        <Head>
          <Title>{Bespeak.SuccessModalTitle[language]}</Title>
          <div />
        </Head>
      </Wrapper>
    </Switcher>
  ); 
} 

export default SuccessModal;

export const X: React.FC<{ style?: any }> = ({ style, children}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="13" height="13">
      <defs>
        <image width="13" height="13" id="img1" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAk0lEQVQoU53S2Q3CMBBF0fvqCwIKgbSA04JJIyBIe4McjIUXjMBf/pijWQVgRwaduYd/79nITp6LAgBuwKYHU5wY9MrUgxnwLCvqwRKE2IRasAUqlEExYZwIPXiW9wFlmVKpI24FhtPMVE60QqkkwyFca6rtnmJJn9ZRT6/ooQV/21M8gH8uYv/MdGCrmevX24txD+8gW4ENLkjoAAAAAElFTkSuQmCC"/>
      </defs>
      <g id="форма копия">
        <g id="Группа 2">
          <use id="Фигура 3" href="#img1" transform="matrix(2,0,0,2,0,0)"/>
        </g>
      </g>
    </svg>
  )
}

const Head = styled.div`
  ${flexCSS('row', 'center', 'space-between')}
  width: 100%;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 13pt;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-left: 10%;
`;


const AnimationSwitchSideIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const AnimationSwitchSideOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnimationSwitchMenuSideIn = keyframes`
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
`;

const AnimationSwitchMenuSideOut = keyframes`
  0% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 100%;
    opacity: 0;
  }
`;

const Switcher = styled.div<{type: 'in' | 'out'}>`
  ${flexCSS('row')}
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  background-color: #00000090;
  ${props => props.type === 'in' ? 'opacity: 1;' : 'opacity: 0;'}
  animation: ${props => props.type === 'in' ? AnimationSwitchSideIn : AnimationSwitchSideOut} 0.5s ease-in-out 0s 1;
`;

const Wrapper = styled.div<{type: 'in' | 'out'}>`
  transition: 0.7s;
  width: 600px;
  background: var(--theme-bg-form-color);
  ${flexCSS('column')}
  color: var(--theme-font-color);
  box-shadow: var(--theme-form-shadow);
  border: 1px solid var(--c-orange);
  border-radius: 5px;
  padding: 40px;
  animation: ${props => props.type === 'in' ? AnimationSwitchMenuSideIn : AnimationSwitchMenuSideOut} 0.5s ease-in-out 0s 1;
  @media ${device.tablet} {
    width: 500px;
  }
  @media ${device.mobileC} {
    width: 400px;
    padding: 30px;
  }
  @media ${device.mobileL} {
    width: 300px;
    padding: 20px;
  }
`;