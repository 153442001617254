import React, { useContext } from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import Service from './Service';
import { flexCSS } from '../../assets/FitCSS';
import LanguageContext, { TranslatorObject } from '../../contexts/Language';
import { device } from '../../styled-components/MediaQueries';
import { Services } from './JSON';

const ServicesWrapper: React.FC = () => {
  const { language } = useContext(LanguageContext);

  return (
    <ScrollableAnchor id={'services'}>
      <Wrapper>
        <Title>{TranslatorObject.Services.title[language]}</Title>
        <ServicesContainer>
          {
            language ? <>
              <Service key="Web" image="service-comp" title="Web" items={Services.web[language]}/>
              <Service key="Mobile" image="service-mob" title="Mobile" items={Services.mobile[language]}/>
            </> : null
          }
        </ServicesContainer>
      </Wrapper>
    </ScrollableAnchor>
    );
  }
  
export default ServicesWrapper;

const Title = styled.div`
  padding: 30px 0px;
  font-size: 20pt;
  font-weight: 700;
  text-align: start;
  width: 65%;
  margin-left: 7%;
  color: var(--theme-title-color);
  @media ${device.laptop} {
    width: 85%;
    margin-left: 0;
  }
  @media ${device.mobileC} {
    width: 95%;
  }
  @media ${device.mobileC} {
    text-align: center;
    margin-left: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg2-color);
  color: var(--theme-font-color);
  ${flexCSS('column')}
  padding-bottom: 50px;
`;

const ServicesContainer = styled.div`
  ${flexCSS('row')}
  width: 100%;
  @media ${device.tablet} {
    ${flexCSS('column')}
  }
`;