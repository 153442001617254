import React, { useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { flexCSS } from '../../assets/FitCSS';
import LanguageContext from '../../contexts/Language';
import { Bespeak, EData } from './JSON';
import { Button, Input, Arrow } from '../StepsWorking/Form';
import { device } from '../../styled-components/MediaQueries';
import axios from 'axios';
import { API_dev_order } from '../../PHP/API';
import { Messages } from '../../PHP/Messages';
import SuccessModal from '../Greeting/SuccessModal';

const ReCaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  let token;
  if(executeRecaptcha) {
    token = executeRecaptcha('login_page');
    token.then(s => {}).catch(e => {});
    // token.then(s => console.log('success recaptcha', s)).catch(s => console.log('reject recaptcha', s));
  }
  return (
    <div></div>
  );
}

const OrderForm: React.FC<{
  target: 'web' | 'mobile', style?: any,
}> = ({target, style, children}) => {
  const { language } = useContext(LanguageContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [comment, setComment] = useState('');
  const [validation, setValid] = useState<{
    name: boolean, phone: boolean, mail: boolean
  }>({ name: true, phone: true, mail: true });

  const [menu, setMenu] = useState(false);

  const handleSubmit = (e: any, callback: Function) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_dev_order}`,
      headers: { 
        'content-type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type'
      },
      data: {
        language,
        name,
        email,
        phone,
        type: target,
        comment,
        message: Messages.toOrderDev
      }
    }).then(result => {
      // console.log('success', result);
      callback();
      setMenu(true)
    }).catch(error => {
      console.log('success', error);
    });
  }

  const sendData = (e: any) => {
    if(!name.length) {
      setValid({...validation, name: false})
      return;
    }
    if(!phone.length) {
      setValid({...validation, phone: false})
      return;
    }
    if(!email.length) {
      setValid({...validation, mail: false})
      return;
    }
    
    handleSubmit(e, () => {
      setName('');
      setEmail('');
      setPhone('');
      setComment('');
    });
  }

  const set = (type: EData, event: any) => {
    switch (type) {
      case 'comment': setComment(event.target.value); break;
      case 'name': 
        if(event.target.value.length) setValid({...validation, name: true})
        setName(event.target.value); 
        break;

      case 'email': 
        if(event.target.value.length) setValid({...validation, mail: true})
        setEmail(event.target.value); 
        break;

      case 'phone': 
        if(event.target.value.length) setValid({...validation, phone: true})
        setPhone(event.target.value); 
        break;

      default: break;
    } 
  }

  return (
    <Wrapper style={style}>
      <CInput valid={!validation.name} placeholder={Bespeak.Form.placeholderName[language]} value={name} onChange={(event: any) => set('name', event)} />
      <CInput valid={!validation.phone} placeholder={Bespeak.Form.placeholderPhone[language]} value={phone} onChange={(event: any) => set('phone', event)} />
      <CInput valid={!validation.mail} placeholder={Bespeak.Form.placeholderEmail[language]} value={email} onChange={(event: any) => set('email', event)} />
      <Textarea placeholder={Bespeak.Form.placeholderComment[language]} value={comment} onChange={(event: any) => set('comment', event)} />
      <CButton onClick={e => sendData(e)}>{Bespeak.Form.buttonText[language]}<Arrow /></CButton>
      <ReCaptchaComponent />
      { menu ? <SuccessModal switch={() => setMenu(false)} /> : null }
    </Wrapper>
  )
}

export default OrderForm;

const MarginBottom = (margin: number = 10, v: '%' | 'px' | 'vh' = 'px') => ({'margin-bottom': `${margin}${v}`});

const Wrapper = styled.div`
  width: 100%;
  background: var(--theme-bg-color);
  ${flexCSS('column', 'flex-start')}
  color: var(--theme-font-color);
  @media ${device.laptop} {
    margin-left: 0 !important;
    width: 100%;
  }
  @media ${device.mobileC} {
    ${flexCSS('column')}
  }
`;

const InvalidAnimation = keyframes`
  0% { box-shadow: inset 0 0 10px red; }
  5% { box-shadow: inset 0 0 9px red; }
  10% { box-shadow: inset 0 0 8px red; }
  15% { box-shadow: inset 0 0 7px red; }
  20% { box-shadow: inset 0 0 6px red; }
  25% { box-shadow: inset 0 0 5px red; }
  30% { box-shadow: inset 0 0 6px red; }
  35% { box-shadow: inset 0 0 7px red;}
  40% { box-shadow: inset 0 0 8px red; }
  45% { box-shadow: inset 0 0 9px red; }
  50% { box-shadow: inset 0 0 10px red; }
  55% { box-shadow: inset 0 0 10px red; }
  60% { box-shadow: inset 0 0 9px red; }
  65% { box-shadow: inset 0 0 8px red; }
  70% { box-shadow: inset 0 0 7px red; }
  75% { box-shadow: inset 0 0 6px red; }
  80% { box-shadow: inset 0 0 5px red; }
  85% { box-shadow: inset 0 0 6px red; }
  90% { box-shadow: inset 0 0 7px red; }
  95% { box-shadow: inset 0 0 8px red; }
  100% { box-shadow: inset 0 0 9px red; }
`;

const DefaultEmptyAnimation = keyframes``;

const CInput = styled(Input)`
  width: 90%;
  background-color: var(--theme-bg2-color);
  ${MarginBottom()}
  box-shadow: var(--theme-inputs-shadow);
  padding: 12px 10px;
  @media ${device.laptop} {
    width: 90%;
  }
  animation: ${props => props.valid ? InvalidAnimation : DefaultEmptyAnimation} 1.5s ease-in-out 0s infinite;
`;

const CButton = styled(Button)`
  margin: 0px;
  width: fit-content;
  margin-top: 10px;
  @media ${device.laptop} {
    width: 93%;
  }  
  @media ${device.laptop} {
    width: 93%;
  }
`;

const Textarea = styled.textarea`
  width: 90%;
  resize: vertical;
  background-color: var(--theme-bg2-color);
  border: none;
  outline: none;
  box-shadow: var(--theme-inputs-shadow);
  &:focus {
    outline: none;
  }
  color: var(--theme-input-color);
  padding: 12px 10px;
  border-radius: 3px;
  height: 60px;
  ${MarginBottom()}
  min-height: 80px;
  max-height: 300px;
`;
