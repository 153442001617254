import React from 'react';
import styled from 'styled-components';

import ThemeContext from '../../contexts/Theme';
import LanguageContext, { TranslatorObject } from '../../contexts/Language';

import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';

const CheckboxTheme: React.FC = () => {
  const { dark, toggle } = React.useContext(ThemeContext);
  const { language } = React.useContext(LanguageContext);
  const Circle = styled.div`
    background: var(--c-orange);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    z-index: 10;
    ${Transition(0.2)}
    ${dark ? 'margin-right: 75%;' : 'margin-right: -75%'}
    cursor: pointer;
    @media ${device.mobile} {
      height: 20px;
      width: 20px;
      ${dark ? 'margin-right: 60%;' : 'margin-right: -60%'}
    }
  `;

  const Text = styled.div`
    position: absolute;
    z-index: 5;
    ${ dark ? 'right: 16%' : 'left: 16%' }
    @media ${device.mobile} {
      display: none;
    }
  `;
  
  const toggleCheck = () => toggle();

  return (
    <Wrapper>
      <ContentCheckbox onClick={() => toggleCheck()}>
        <Text>{dark ? TranslatorObject.Greeting.themeDark[language] : TranslatorObject.Greeting.themeLight[language]}</Text>
        <Circle />
      </ContentCheckbox>
    </Wrapper>
    );
  }
  
export default CheckboxTheme;
  
const Transition = (timing: number) => ({
  transition: `${timing}s`
})
const Wrapper = styled.div`
  color: var(--theme-font-color);
`;

const ContentCheckbox = styled.div`
  background: var(--theme-checkbox-bg);
  color: var(--theme-font-color);
  width: 90px;
  height: 23px;
  border-radius: 15px;
  font-size: 13px;
  color: var(--theme-checkbox-color);
  font-weight: 500;
  ${flexCSS('row', 'center', 'center')}
  position: relative;
  cursor: pointer;
  box-shadow: var(--theme-checkbox-shadow);
  @media ${device.mobile} {
    width: 50px;
    height: 19px;
  }
`;

