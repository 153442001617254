import React from 'react';
import styled from 'styled-components';

import { flexCSS } from '../../assets/FitCSS';
import { device } from '../../styled-components/MediaQueries';

export interface IMember {
  id: number,
  name: string,
  post: string,
  avatar: string,
  linkLinkedin:string,
}

export interface IImage {
  source: string
}

const Member: React.FC<{
  member: IMember
}> = ({ member, children }) => {
  const { id, name, post, avatar, linkLinkedin } = member;
  const handleClick = (link: string) => {
    if (`${link}` === " ") {
      return
    }
    else {
      window.open(`${link}`, '_blank');
    } 
  }
  
  return (
    <Wrapper key={1} Zindex={id} onClick={()=> handleClick(linkLinkedin)}>
      <LeftSide>
        <Image src={require('../../assets/images/crew/crew' + avatar + '.png')} source={avatar} />
      </LeftSide>
      <RightSide>
        <Title>{name}</Title>
        <Post>{post}</Post>
        </RightSide>
    </Wrapper>
  );
}

export default Member;

const Title = styled.div`
  font-weight: 500;
  font-size: 11pt;
  color: var(--theme-font-color);
  white-space: nowrap;
  @media ${device.mobileC} {
    text-align: center;
    width: 100%;
  }
  @media ${device.mobileC} {
    text-align: center;
    width: 100%;
  }
  @media ${device.mobileXM} {
    text-align: center;
    font-size: 9pt;
  }
`;

const Wrapper = styled.div<{
  Zindex: number,
}>`
  transition: 0.3s;
  color: var(--theme-font-color);
  padding-bottom: 20px;
  ${flexCSS('row', 'center', 'center')};
  &:hover ${Title} {
    color: var(--c-orange);
  }
  cursor: pointer;
  width: 30%;
  z-index: ${(props: { Zindex: number }) => props.Zindex};
  @media ${device.tablet} {
    width: 45%;
  }
  @media ${device.mobileC} {
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

const LeftSide = styled.div`
  
`;

const RightSide = styled.div`
  ${flexCSS('column', 'flex-start', 'space-around')}
  width: 50%;
  margin-left: 10px;
  @media ${device.mobileC} {
    margin-left: 0px;
    width: fit-content;
  }
`;

const Image = styled.img<IImage>`
  width: 95px;
  @media ${device.mobileC} {
    width: 80px;
  }
`;

const Post = styled.div`
  font-weight: 400;
  font-size: 10pt;
  color: var(--theme-font-color);
  white-space: wrap;
  @media ${device.mobileC} {
    text-align: center;
    width: 100%;
  }
  @media ${device.mobileXM} {
    text-align: center;
    font-size: 9pt;
  }
`;

