interface IFlex {
  [property: string]: string
}

interface IMethod {
  (direction: 'row' | 'column', align?: string, justify?: string): IFlex
}

export const flexCSS: IMethod = (
  direction: 'row' | 'column', align: string = 'center', justify: string = 'center'
): IFlex => {
  let flexObject: IFlex = {
    'display': 'flex',
    'flex-direction': direction,
    'align-items': align,
    'justify-content': justify 
  }
  return flexObject;
}