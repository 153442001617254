import { createGlobalStyle } from "styled-components";

export const GlobalFontsStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Exo+2:100,200,300,400,500,600,700,800,900&display=swap');
  body {
    font-family: 'Exo 2';
  }
  .bx-touch.crm-widget-button-mobile {
    height: auto !important;
    overflow-behaviour: contain;
  }
  .bx-touch .b24-widget-button-shadow {
    overscroll-behavior: contain;
    overflow: scroll;
  }
`;

export const fonts: {
  [font: string]: string
} = {
  general: 'Exo 2'
}
