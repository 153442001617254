import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { goToAnchor } from 'react-scrollable-anchor';

import LanguageContext from '../../contexts/Language';
import AnchorContext from '../../contexts/Anchor';
import { flexCSS } from '../../assets/FitCSS';
import { HeadData } from '../Header/JSON';

interface IModal {
  switch: Function
}

const SwitcherMenu: React.FC<IModal> = (props) => {
  const { language } = useContext(LanguageContext);
  const [side, sideOut] = useState<'in' | 'out'>('in');

  const { anchore, toggleAnchor } = useContext(AnchorContext);

  const handleClick = () => {
    sideOut('out');
    setTimeout(() => props.switch(), 700);
  }

  const handleAnchor = (anchor: 'greeting' | 'services' | 'portfolio' | 'products' | 'stages' | 'rules' | 'crew' | 'whoarewe' | 'toorder') => {
    goToAnchor(anchor, true);
    toggleAnchor(anchor);
  }

  return (
    <Switcher type={side} onClick={() => handleClick()}>
      <Wrapper type={side}>
        <Menu type={side}>
          <Item active={anchore === 'services'} onClick={() => handleAnchor('services')}>{HeadData.services[language]}</Item>
          <Item active={anchore === 'portfolio'} onClick={() => handleAnchor('portfolio')}>{HeadData.portfolio[language]}</Item>
          <Item active={anchore === 'products'} onClick={() => handleAnchor('products')}>{HeadData.products[language]}</Item>
          <Item active={anchore === 'stages'} onClick={() => handleAnchor('stages')}>{HeadData.steps[language]}</Item>
          <Item active={anchore === 'rules'} onClick={() => handleAnchor('rules')}>{HeadData.rules[language]}</Item>
          <Item active={anchore === 'crew'} onClick={() => handleAnchor('crew')}>{HeadData.crew[language]}</Item>
          <Item active={anchore === 'whoarewe'} onClick={() => handleAnchor('whoarewe')}>{HeadData.whoarewe[language]}</Item>
          <Item active={anchore === 'toorder'} onClick={() => handleAnchor('toorder')}>{HeadData.toorder[language]}</Item>
          {/* <Item active={language === 'ua'} onClick={() => toggle('ua')}>UA</Item>
          <Item active={language === 'ru'} onClick={() => toggle('ru')}>RU</Item>
          <Item active={language === 'en'} onClick={() => toggle('en')}>EN</Item> */}
        </Menu>
      </Wrapper>
    </Switcher>
  );
}

export default SwitcherMenu;

const AnimationSwitchSideIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const AnimationSwitchSideOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnimationSwitchMenuSideIn = keyframes`
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
`;

const AnimationSwitchMenuSideOut = keyframes`
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
`;

const Switcher = styled.div<{ type: 'in' | 'out' }>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  background-color: #00000090;
  ${props => props.type === 'in' ? 'opacity: 1;' : 'opacity: 0;'}
  animation: ${props => props.type === 'in' ? AnimationSwitchSideIn : AnimationSwitchSideOut} 0.5s ease-in-out 0s 1;
`;

const Wrapper = styled.div<{ type: 'in' | 'out' }>`
  width: 100%;
  height: 100%;
  ${flexCSS('row')}
  position: relative;
`;

const Menu = styled.div<{ type: 'in' | 'out' }>`
  top: 0;
  padding-top: 60px;
  right: 0;
  height: 100%;
  background: var(--theme-bg2-color);
  width: 250px;
  ${flexCSS('column', 'center', 'flex-start')}
  position: absolute;
  animation: ${props => props.type === 'in' ? AnimationSwitchMenuSideIn : AnimationSwitchMenuSideOut} 0.5s ease-in-out 0s 1;
`;

const Item = styled.div<{ active: true | false }>`
  width: 85%;
  padding: 10px;
  background: var(--theme-sidemenu-button-bg);
  transition: 0.3s;
  margin: 3px;
  margin-bottom: 10px;
  font-weight: 700;
  border-radius: 3px;
  color: var(--theme-sidemenu-button-color);
  opacity: 1;
  cursor: pointer;
  &:hover {
    box-shadow: var(--theme-sidemenu-button-shadow);
  }
  ${props => props.active ? 'color: white;' : null}
  ${props => props.active ? 'background-color: var(--c-orange);' : null}
  ${props => props.active ? 'box-shadow: 0px 0px 7px var(--c-orange);' : null}
`;