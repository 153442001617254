interface ILanguage {
  [lang: string]: string
}

interface IContacts {
  Abbr: ILanguage,
  LineUp: ILanguage,
  PrimaryPhone: string,
  SecondaryPhone: string,
  Mail: string,
  Code: string
}

export const Contact: IContacts = {
  Abbr: {
    ru: 'ЕГРПОУ',
    en: 'EDPNOU',
    ua: 'ЄДРПОУ'
  },
  LineUp: {
    ru: 'ООО "КИТ ГЛОБАЛ" - компания по разработки  мобильных приложений (product&outsource)',
    en: 'LLC "KIT GLOBAL" - mobile app development company (product&outsource)',
    ua: 'ТОВ "КІТ ГЛОБАЛ" -  компанія розробки мобільних застосунків (product&outsource)'
  },
  PrimaryPhone: '+38 091 48 100 23',
  SecondaryPhone: '+38 099 007 95 47',
  Mail: 'info@kitapp.pro',
  Code: '433444434'
}