import React, { useState, useLayoutEffect} from 'react';

const ThemeContext = React.createContext({
  dark: false,
  toggle: () => {},
});

export default ThemeContext;

export const ThemeProvider = (props: any) => {
  const [dark, setDark]: any = useState(window.localStorage.getItem('darkTheme') ? window.localStorage.getItem('darkTheme') : 'true');

  useLayoutEffect(() => {
    const lastTheme = window.localStorage.getItem('darkTheme');
    if (lastTheme === 'true') {
      setDark(false);
      applyTheme(lightTheme);
    }
    if (!lastTheme || lastTheme === 'false') {
      setDark(true);
      applyTheme(darkTheme);
    } 
  }, [dark]);

  const applyTheme = (theme: any) => {
    const root = document.getElementsByTagName('html')[0];
    root.style.cssText = theme.join(';');
  }

  const toggle = () => {
    setDark(!dark);
    window.localStorage.setItem('darkTheme', String(dark));
  };

  return <ThemeContext.Provider value={{ dark, toggle }}>
    { props.children }
  </ThemeContext.Provider>
}

export const lightTheme = [
  '--theme-font-color: #333333',
  '--theme-bg-color: #ffffff90',
  '--theme-bg2-color: #f1f1f1',
  '--theme-bg-form-color: #ffffff',
  '--theme-checkbox-bg: #f1f1f1',
  '--theme-checkbox-color: #cccccc',
  '--theme-checkbox-shadow: inset 0px 0px 3px 1px #cdcdcd',
  '--theme-portfolio-button-color: #fd6d00',
  '--theme-portfolio-button-active-color: #ffffff',
  '--theme-p-link-color: #8a8a8a',
  '--theme-step-color: #999999',
  '--color: #333333',
  '--theme-title-color: #fd6d00',
  '--theme-input-color: #353535',
  '--theme-input-bg: #f1f1f1',
  '--theme-input-shadow: inset 0 0 8px #9a9a9a',
  '--theme-form-shadow: none',
  '--theme-form-button-shadow: none',
  '--theme-form-button-color: var(--c-orange)',
  '--theme-form-button-bg: none',
  '--theme-inputs-shadow: inset 0 0 5px #9a9a9a',
  '--theme-from-button-brief-color: var(--c-orange)',
  '--theme-footer-font-color: #333333',
  '--theme-sidemenu-button-bg: lightgrey',
  '--theme-sidemenu-button-color: var(--theme-font-color)',
  '--theme-sidemenu-button-shadow: 0px 0px 7px #555555',
  '--theme-slider-border: grey'
];

export const darkTheme = [
  '--theme-font-color: #ffffff',
  '--theme-bg-color: #1d1d1d',
  '--theme-bg2-color: #161616',
  '--theme-bg-form-color: #090909',
  '--theme-checkbox-bg: #000000',
  '--theme-checkbox-color: #cccccc',
  '--theme-checkbox-shadow: none',
  '--theme-portfolio-button-color: #ffffff',
  '--theme-portfolio-button-active-color: #ffffff',
  '--theme-p-link-color: #565656',
  '--theme-step-color: #303030',
  '--color: grey',
  '--theme-title-color: ',
  '--theme-input-color: lightgrey',
  '--theme-input-bg: #1d1d1d',
  '--theme-input-shadow: none',
  '--theme-form-shadow: inset 0 0 20px var(--c-orange)',
  '--theme-form-button-shadow: 0 0 20px var(--c-orange)',
  '--theme-form-button-color: var(--theme-font-color)',
  '--theme-form-button-bg: black',
  '--theme-inputs-shadow: inset 0 0 5px #00000063',
  '--theme-from-button-brief-color: white',
  '--theme-footer-font-color: #8d8d8d',
  '--theme-sidemenu-button-bg: black',
  '--theme-sidemenu-button-color: var(--theme-font-color)',
  '--theme-sidemenu-button-shadow: 0px 0px 7px var(--c-orange)',
  '--theme-slider-border: lightgrey'
];